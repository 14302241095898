import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IStateShared, IProductRestrictions } from '../interface';

const state = createFeatureSelector<IStateShared, IProductRestrictions>('productRestrictions');

export const isProductRestrictionsDowlanding = createSelector(
    state,
    productRestrictions => productRestrictions.isDownloading
);

export const hasProductRestrictionsFailed = createSelector(
    state,
    productRestrictions => productRestrictions.hasFailed
);

export const hasProductRestrictionsSucceeded = createSelector(
    state,
    productRestrictions => productRestrictions.hasSucceeded
);

export const getDataForProductRestrictions = createSelector(
    state,
    productRestrictions => ({
        restrictions: productRestrictions.data,
        locationNo: productRestrictions.LocationNo,
        dateToCheck: productRestrictions.DateToCheck,
    })
);

export const getProductRestrictions = createSelector(
    state,
    productRestrictions => productRestrictions
);

