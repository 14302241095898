export class VendorPayments {
    public static canMakePayments = window.ApplePaySession
    && ApplePaySession !== null
    && ApplePaySession !== undefined
    && ApplePaySession.canMakePayments();

    /**
     * Check if application is configured to use Apple Pay
     * @param {IConfig} config
     * @returns {boolean}
     */
    public static isAppConfigured(config: IConfig): boolean {
        return Boolean(config.payments?.applePay?.enabled && config.payments?.applePay?.merchantId);
    }

    /**
     * Check if client can pay with Apple Pay payment method
     * @param {IConfig} config
     * @returns {boolean}
     */
    public static clientCanUseApplePayMethod(config: IConfig): boolean {
        return VendorPayments.canMakePayments && VendorPayments.isAppConfigured(config);
    }

    public static isAnyVendorPaymentMethodAvailable(config: IConfig): boolean {
        return VendorPayments.clientCanUseApplePayMethod(config) && config?.payments?.applePay?.enabled === true
            || config.payments?.googlePay?.enabled === true && Boolean(config.payments?.googlePay?.gateway) && Boolean(config.payments?.googlePay?.gatewayMerchantId)
            || config.payments?.payInStore === true;
    }
}
