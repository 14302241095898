import { Injectable, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as selectors from '@shared/state/selectors';
import * as actions from '@shared/state/actions';

import * as State from '@shared/state';
import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

import { Observable, of } from 'rxjs';
import { map, distinctUntilChanged, filter, combineLatest, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ImagesController {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _store: Store<State.IStateShared>,
    ) { }

    public imageForOrderType$(orderTypeId: number): Observable<State.IOrderTypeImage> {
        return this._store
            .pipe(
                select(selectors.getOrderTypeImage(orderTypeId))
            );
    }

    public imageForCurrentLocation$(): Observable<string> {
        return this._store.select(selectors.getLocationImageForCurrentLocation);
    }

    public imageForOnlineMenuPageProduct$(pageId: number, productId: number) {
        return this._store
            .pipe(
                select(selectors.getImageForOnlineMenuPageProduct(pageId, productId)),
                distinctUntilChanged(),
            );
    }

    public dietaryTagsImages(dietaryTags: APICommon.ITagModel[]): Observable<State.IDietaryTagImage[]> {
        return this._store.pipe(
            select(selectors.getDietaryTagsByTags(dietaryTags || []))
        );
    }

    public wizzardMenuFlowProductImage$(productId: number): Observable<State.IProductImage> {
        return this._store
            .pipe(
                select(selectors.getImageForProduct(productId)),
                distinctUntilChanged(),
                filter(image => image !== undefined),
            );
    }

    public venueImage$(imageType: OLO.Enums.IMAGE_TYPE = OLO.Enums.IMAGE_TYPE.ForWeb): Observable<OLO.Common.IImageStateObj> {
        const venueId: number = this._config.venue && this._config.venue.id ? this._config.venue.id : null;

        return !venueId ? of(null) : this._store
            .pipe(
                select(
                    selectors.getVenueImage(imageType, venueId)
                ),
            );
    }

    public currentLocationImage$(): Observable<string> {
        return this._store
            .pipe(
                select(selectors.getLocationImageForCurrentLocation)
            );
    }

    public heroHeaderImage$(imageType: OLO.Enums.IMAGE_TYPE = OLO.Enums.IMAGE_TYPE.ForWeb): Observable<string> {
        const defaultImg: string = '/assets/images/hero.bg.jpg';
        const defaultLoyaltyImg: string = '/assets/images/loyalty.bg.jpg';

        return this._store
            .pipe(
                select(selectors.routeIsLocationDetailsPage(this._config)),
                combineLatest(
                    this._store
                        .pipe(
                            select(selectors.routeIsErrorPage()),
                        ),
                    this._store
                        .pipe(
                            select(selectors.routeIsAccountPage())
                        ),
                    this._store
                        .pipe(
                            select(selectors.isCurrentRouteLoyalty)
                        ),
                    this._store
                        .pipe(
                            select(
                                selectors.getAppLocationMode
                            ),
                        ),
                    this._store
                        .pipe(
                            select(
                                selectors.routeIsOrderConfirmationPage()
                            ),
                        ),
                    this._store
                        .pipe(
                            select(
                                selectors.routeIsOrderConfirmationMembershipOnlyPage()
                            ),
                        ),
                ),
                switchMap(([isLocationDetailsPage, isErrorPage, isAccountPage, isLoyaltyPage, appLocationMode, isOrderConfirmationPage,
                    isMembershipOnlyPage]) => {
                    if (isLoyaltyPage) return of(defaultLoyaltyImg);

                    if (isAccountPage || isErrorPage || isOrderConfirmationPage || isMembershipOnlyPage) return of(null);

                    if (isLocationDetailsPage) {
                        return this._store
                            .pipe(
                                select(selectors.currentLocationNoByRoute),
                                filter(locationNo => locationNo !== undefined),
                                switchMap(locationNo => this._store
                                    .pipe(
                                        select(selectors.getLocationImage(OLO.Enums.IMAGE_TYPE.ForWeb, locationNo)),
                                        map(obj => {
                                            if (obj && obj.data && obj.data.ImageUrl) {
                                                return obj.data.ImageUrl;
                                            }

                                            return null;
                                        })
                                    ))
                            );
                    }

                    if (appLocationMode === OLO.Enums.APP_MODE.VENUE) {
                        return this.venueImage$()
                            .pipe(
                                filter(obj => obj.isDownloading === false),
                                map(obj => {
                                    if (obj !== null && obj !== undefined && obj.data && obj.data.ImageUrl) {
                                        return obj.data.ImageUrl;
                                    }

                                    if (obj.hasSucceeded === false && obj.hasFailed === false) {
                                        return null;
                                    }

                                    return defaultImg;

                                })
                            );
                    }

                    /* Default preuploaded locally image */
                    return of(defaultImg);
                }),
            );
    }

    public getImageForFreeProduct$(productId: number): Observable<State.IFreeProductImage> {
        return this._store
            .pipe(
                select(selectors.getFreeProductsImageImage(productId))
            );
    }

    public getImageForFreeProductByFreeProductId$(freeProductId: number): Observable<State.IFreeProductImage> {
        return this._store
            .pipe(
                select(selectors.getFreeProduct(freeProductId)),
                switchMap(freeProduct => {
                    if (!freeProduct) return of(null);

                    return this.getImageForFreeProduct$(freeProduct.ProductId);
                })
            );
    }

    public requestHeroLocationImage(locationNo: number): void {
        const { width, height } = Utils.Dimensions.caluclateHeroImageSize();

        return this._store.dispatch(
            actions.LocationImagesRequest(
                [locationNo],
                OLO.Enums.IMAGE_TYPE.ForWeb,
                width,
                height
            ));
    }

    public requestThumbForLocations(locationNos: number[]): void {
        const { width, height } = Utils.Dimensions.calculateThumbSize();

        return this._store.dispatch(
            actions.LocationImagesRequest(
                locationNos,
                OLO.Enums.IMAGE_TYPE.ForList,
                width,
                height
            ));
    }

    public requestImageForOnlineMenuPage(pageId: number): void {
        return this._store.dispatch(
            actions.OnlineMenuPagesImagesRequest({
                params: {
                    width: window.innerWidth,
                },
                imageType: OLO.Enums.IMAGE_TYPE.ForWeb,
                pageIds: [pageId]
            })
        );
    }

    public requestImagesForOnlineMenuPageProducts(pageId: number): void {
        this._store
            .dispatch(
                actions.OnlineMenuProductsImagesRequest({ params: {
                    width: 150,
                    height: 150,
                }, pageId })
            );
    }
}
