import { PAYMENT_PROVIDERS_REQUIRED_BILLING_DETAILS } from '@shared/core/consts';

export class BillingDetails {
    public static isBillingDetailsRequired(paymentProvider: OLO.Enums.PAYMENT_PROVIDER): boolean {
        if(!paymentProvider) {
            return false;
        }

        return PAYMENT_PROVIDERS_REQUIRED_BILLING_DETAILS.includes(paymentProvider);
    }
}
