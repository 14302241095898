import { Injectable, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as selectors from '@shared/state/selectors';

import * as State from '@shared/state';
import * as Tokens from '@shared/core/tokens';
import * as Models from '@shared/core/models';

import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { IProductRestriction } from '@shared/state';

@Injectable({
    providedIn: 'root'
})
export class ProductRestrictionsController {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _store: Store<State.IStateShared>,
    ) { }

    public getProductRestrictionsForLocation$(locationNo: number): Observable<IProductRestriction[]> {
        return this._store
            .pipe(
                select(selectors.restrictionForLocationOrderType(locationNo, this._config))
            );
    }

    public restrictionsCartLimitReport$(filterExceeded: boolean = true, limit: number = 1, locationNo: number): Observable<Models.RestrictionItem[]> {
        return this._store
            .pipe(
                select(selectors.restrictionsLimit(locationNo)),
                map(reports => {
                    let filtered = filterExceeded ? reports?.filter(obj => obj.isExceeded === true) : reports ? [...reports] : null;

                    if (limit && filtered) {
                        filtered = filtered.filter((obj, index) => index < limit);
                    }

                    return filtered;
                })
            );
    }

    public hasExceededProductsLimit$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.getCart),
                switchMap(cart => this._store
                    .pipe(
                        select(selectors.hasExceededProductsLimit(cart.locationNo))
                    ))
            );
    }

    public isRestrictionsDownlanding$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isProductRestrictionsDowlanding)
            );
    }
}
