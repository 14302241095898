import { OrderPaymentMethod, OrderPaymentDetails } from './order-payment-method';

export class OrderPaymentWithAccountCharge extends OrderPaymentMethod {
    public async generatePaymentDetails(details: OrderPaymentDetails) {
        const { cardState: cardsState, onlineOrder, paymentMethod } = details;

        if(!this._isVendorPayment(paymentMethod) && cardsState.activeCardId === OLO.Enums.PAYMENT_SERVICE.ACCOUNT_CHARGE) {
            this._executePaymentModel.Amount = onlineOrder.TotalGrossValue;

            return this._executePaymentModel;
        }

        if(this._next) {
            return this._next.generatePaymentDetails(details);
        }

        return this._executePaymentModel;
    }
}
