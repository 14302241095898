import { Inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as Tokens from '@shared/core/tokens';
import * as Services from '@shared/core/services';
import * as selectors from '@shared/state/selectors';
import * as Utils from '@shared/core/utils';
import * as State from '@shared/state';

import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class TranslationController {
    private _possibleVariables: OLO.Types.CONFIRMATION_PAGE_VARIABLE[] = [
        'pickupDate',
        'orderNumber',
        'locationName',
        'orderTypeName',
        'locationAddress',
        'orderTypeDetails',
        'orderTypeDetailValue',
    ];
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private readonly _config: IConfig,
        private _store: Store<State.IStateShared>,
        private _queryParamsService: Services.QueryParamsService,
    ) {}

    public getAvailableValuesToInsert$(orderId?: number, locationNo?: number): Observable<OLO.Types.CONFIRMATION_PAGE_VARIABLES_MAP> {
        return combineLatest(
            this._handlePickupDate(orderId),
            this._handleOrderNumber(),
            this._handleLocationName(locationNo),
            this._handleOrderTypeName(orderId, locationNo),
            this._handleLocationAddress(locationNo),
            this._handleOrderTypeDetails(orderId),
            this._handleOrderTypeDetailValue(),
        ).pipe(
            map((values) =>
                this._possibleVariables.reduce((obj, keyName, i) => {
                    obj[keyName] = values[i];

                    return obj;
                }, {} as OLO.Types.CONFIRMATION_PAGE_VARIABLES_MAP),
            ),
        );
    }

    private _handleOrderNumber(): Observable<string> {
        return this._getOrderId$().pipe(map((orderId) => (!orderId ? '' : `${orderId}`)));
    }

    private _handleLocationName(locationNoArg: number): Observable<string> {
        return this._getLocationNo$().pipe(
            switchMap((locationNo) => this._store.pipe(select(selectors.getLocationDetails(locationNo || locationNoArg)))),
            distinctUntilChanged((a, b) => a?.LocationFriendlyName === b?.LocationFriendlyName),
            map((location) => {
                if (!location) return '';

                return location.LocationFriendlyName || '';
            }),
        );
    }

    private _handleLocationAddress(locationNoArg: number): Observable<string> {
        return this._getLocationNo$().pipe(
            switchMap((locationNo) => this._store.pipe(select(selectors.getLocationDetails(locationNo || locationNoArg)))),
            distinctUntilChanged((a, b) => a?.StreetAddress === b?.StreetAddress || a?.Suburb === b?.Suburb || a?.PostCode === b?.PostCode),
            map((location) => {
                if (!location) return '';
                const arr: string[] = [];
                if (location.StreetAddress) arr.push(location.StreetAddress);
                if (location.Suburb) arr.push(location.Suburb);
                if (location.PostCode) arr.push(location.PostCode);

                return arr.join(', ') || '';
            }),
        );
    }

    private _handleOrderTypeDetailValue(): Observable<string> {
        return this._getOrderId$().pipe(
            switchMap((orderId) => this._store.pipe(select(selectors.getHistoryOrder(orderId)))),
            map((order) => {
                const orderType = order?.data?.OrderTypeDetails?.find((obj) => obj.ValueProvided !== null);

                return orderType?.ValueProvided || '';
            }),
        );
    }

    private _handlePickupDate(orderIdArg: number): Observable<string> {
        const type = 'orderConfirmation';
        const format = 'ddd, Do of MMM';

        return this._getOrderId$().pipe(
            switchMap((orderId) => this._store.pipe(select(selectors.getHistoryOrder(orderId || orderIdArg)))),
            map((order) => {
                const pickupDate = order?.data?.PickUpDate;
                if (!pickupDate) return '';

                const converted: Date = Utils.Dates.createDate(pickupDate);
                if (converted.toString() === 'Invalid Date') return pickupDate;

                return Utils.Pickups.createPickupLabelName(
                    this._config.futureOrders,
                    type,
                    converted,
                    Utils.Dates.datesDiffInDays(new Date(), converted) === 0,
                    format,
                    null,
                );
            }),
        );
    }

    private _handleOrderTypeName(orderIdArg: number, locationNoArg: number): Observable<string> {
        return combineLatest(this._getOrderId$(), this._getLocationNo$()).pipe(
            switchMap(([orderId, locationNo]) => this._store.pipe(select(selectors.getOrderTypeDescriptionForOrder(orderId || orderIdArg, locationNo || locationNoArg)))),
            map((orderTypeName) => {
                if (!orderTypeName) return '';

                return orderTypeName;
            }),
        );
    }

    private _handleOrderTypeDetails(orderIdArg: number): Observable<string> {
        return this._getOrderId$().pipe(
            switchMap((orderId) => this._store.pipe(select(selectors.getHistoryOrder(orderId || orderIdArg)))),
            map((order) => (order?.data?.OrderTypeDetails?.reduce((acc, orderType) => [...acc, orderType.ValueProvided], []) || []).join(', ') || ''),
        );
    }

    private _getOrderId$(): Observable<number> {
        return this._store.pipe(
            select(selectors.getCurrentRouteQueryParams),
            switchMap((params) => {
                if (!params || !params.order) return of(null);

                const decryptedParams = this._queryParamsService.decryptOrderData(params);
                if (!decryptedParams) return of(null);

                return this._store.pipe(
                    select(selectors.getHistoryOrder(decryptedParams.orderId)),
                    map((order) => (order ? order.OrderId || null : null)),
                );
            }),
        );
    }

    private _getLocationNo$(): Observable<number> {
        return this._store.pipe(
            select(selectors.getCurrentRouteQueryParams),
            map((params) => {
                if (!params || !params.order) return null;
                const decryptedParams = this._queryParamsService.decryptOrderData(params);

                return decryptedParams ? decryptedParams.locationNo : null;
            }),
        );
    }
}
