import { Injectable, Inject } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Services from '@shared/core/services';
import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

import * as StateModels from '../interface';

import { Observable, throwError, of, never, forkJoin } from 'rxjs';
import { catchError, switchMap, map, tap, withLatestFrom, filter, take, delay, auditTime, combineLatest } from 'rxjs/operators';

@Injectable()
export class RouterEffects {
    @Effect({ dispatch: false }) public redirectToHomeOnSuccessfulSignIn$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.MemberJwtDataResponseSuccess
            ),
            withLatestFrom(
                this._store.pipe(select(selectors.isMemberAuthorized)),
                this._store
                    .pipe(
                        select(selectors.isEmailConfirmationPage)
                    ),
                this._store
                    .pipe(
                        select(selectors.isResetPasswordPage)
                    ),
                this._store
                    .pipe(
                        select(selectors.isError404Page)
                    ),
                this._store
                    .pipe(
                        select(selectors.isCurrentRouteLoyalty)
                    )
            ),
            switchMap(([action, isMemberAuthorized, isEmailConfrimationPage, isResetPasswordPage, isError404Page, isLoyaltyPage]) => {
                const isLoyaltyModeOnly = this._config.appMode === IAppMode.LOYALTY_ONLY;

                if (isLoyaltyPage && this._config.appMode !== IAppMode.ORDERING_ONLY) {
                    this._routeService.navigateToProfileLoyaltyHome();

                    return never();
                }

                // The condition is required for case when unauthorized user is on reset password page, confirm email etc. and he performs login,
                // after that he will be redirected to home page. We check isMemberAuthorized flag for case when user data comes from active
                // session storage and redirect is not undesirable.
                if (isResetPasswordPage || !isMemberAuthorized && (isLoyaltyModeOnly || isEmailConfrimationPage || isError404Page)) {
                    this._routeService.navigateToHomeView();
                }

                return never();
            })
        );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _actions$: Actions,
        private _store: Store<StateModels.IStateShared>,
        private _routeService: Services.RouteService,
    ) { }

}
