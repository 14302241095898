import { createAction, props } from '@ngrx/store';
import * as State from '@shared/state/interface';
import { IAdyenSettingsResponse, ICreatePaymentAccountResponse, IPaymentExpressCardIdResponse, IStripeSettingsResponse } from '@shared/state/interface';

export const CreditCardsStateReset = createAction('[Credit cards] Reset state');

export const CreditCardsResetError = createAction('[Credit cards] Reset errors');

export const CreditCardsStoreWithOrder = createAction(
    '[Credit cards] Store card details tokenized later during order',
    props<{ card: OLO.Members.IMemberCreditCardDetails; }>(),
);

/* Request cards */
export const CreditCardsRequest = createAction('[Credit cards] Request user cards');

export const CreditCardsSuccessRequest = createAction(
    '[Credit cards] Success request user cards',
    props<{ payload: OLO.Members.IMemberCreditCardDetails[]; }>(),
);

export const CreditCardsErrorRequest = createAction(
    '[Credit cards] Error request user cards',
    props<{ ex?: any; }>(),
);

/* Request cards token */
export const GetCreditCardToken = createAction(
    '[Credit cards] Request token for a new card',
    props<OLO.CreditCards.ICreditCardDetails>(),
);
export const GetCreditCardTokenWithRedirect = createAction(
    '[Credit cards] Request token for a new card with redirect',
    props<OLO.CreditCards.ICreditCardDetails>(),
);
export const __DEMO__getCardToken = createAction(
    '[Credit cards - DEMO] Request token for a new card',
    props<OLO.CreditCards.ICreditCardDetails>(),
);

export const CreditCardsSuccessRequestToken = createAction(
    '[Credit cards] token for a new card success',
    props<State.ICreditCardTokenResponse>(),
);
export const CreditCardsSuccessRequestTokenWithRedirect = createAction(
    '[Credit cards] token for a new card success with redirect',
    props<State.ICreditCardTokenResponse>(),
);
export const __DEMO__CreditCardsSuccessRequestToken = createAction(
    '[Credit cards - DEMO] token for a new card success',
    props<State.ICreditCardTokenResponse>(),
);

export const CreditCardsErrorRequestToken = createAction(
    '[Credit cards] token for a new card error',
    props<{ ex?: any; }>(),
);

export const CreditCardsErrorRequestTokenWithRedirect = createAction(
    '[Credit cards] token for a new card error with redirect',
    props<{ ex?: any; }>(),
);

/* Add card */
export const CreditCardsAddRequest = createAction(
    '[Credit cards] Request add new card',
    props<{ card: OLO.Members.IMemberCreditCardDetails; }>(),
);
export const CreditCardsAddAfterRedirectRequest = createAction(
    '[Credit cards] Request add new card after redirect',
    props<{ card: OLO.Members.IMemberCreditCardDetails; }>(),
);

export const CreditCardsAddSuccessRequest = createAction(
    '[Credit cards] Success request add new card',
    props<{ newCard: ICreatePaymentAccountResponse; }>(),
);

export const CreditCardsAddAfterRedirectSuccessRequest = createAction(
    '[Credit cards] Success request add new card after redirect',
    props<{ card: OLO.Members.IMemberCreditCardDetails; newCard: ICreatePaymentAccountResponse; }>(),
);

export const CreditCardsAddErrorRequest = createAction(
    '[Credit cards] Error request add new card',
    props<{ ex?: any; }>(),
);

export const CreditCardsAddAfterRedirectErrorRequest = createAction(
    '[Credit cards] Error request add new card after redirect',
    props<{ card: OLO.Members.IMemberCreditCardDetails; ex?: any; }>(),
);

export const CreditCardsSelectDefaultPaymentMethod = createAction(
    '[Credit cards] Select default payment method'
);

export const CreditCardShowForm = createAction(
    '[Credit cards] Set is credit card adding mode',
    props<{ isAdding: boolean; }>(),
);

export const SelectActiveCreditCardId = createAction(
    '[Credit cards] select active card id',
    props<{ cardId: number | string; }>(),
);

export const SetCardBillingAddress = createAction(
    '[Credit cards] Set card billing details',
    props<{ cardId: number | string; billingDetails: OLO.CreditCards.CreditCardBillingDetails; }>(),
);

export const SelectActiveCreditCardToken = createAction(
    '[Credit cards] select active card token',
    props<{ token: string; }>(),
);

export const AddCardToState = createAction(
    '[Credit cards] add card to state only',
    props<{ card: OLO.Members.IMemberCreditCardDetails; }>(),
);

export const CreditCardTokenDataReset = createAction('[Credit cards] reset current token data');

/* Delete card  */
export const CreditCardsRemoveRequest = createAction(
    '[Credit cards] Request remove card',
    props<{ cardId: number | string; }>(),
);

export const CreditCardsRemoveSuccessRequest = createAction(
    '[Credit cards] Success request remove card',
    props<{ cardId: number | string; }>(),
);

export const CreditCardsRemoveErrorRequest = createAction(
    '[Credit cards] Error request remove card',
    props<{ ex?: any; }>(),
);

export const CreditCardsValidateReset = createAction(
    '[Credit cards] Reset card validation',
);

export const CreditCardsValidateRequest = createAction(
    '[Credit cards] Request card validation',
    props<{
        responseParams: APICommon.IPaymentProviderPossibleResponseParams;
    }>()
);

export const CreditCardsValidateSuccessRequest = createAction(
    '[Credit cards] Success request card validation',
    props<{ responseParams: APICommon.IPaymentProviderPossibleResponseParams; card: IPaymentExpressCardIdResponse; }>()
);

export const CreditCardsValidateErrorRequest = createAction(
    '[Credit cards] Error request card validation',
    props<{ responseParams: APICommon.IPaymentProviderPossibleResponseParams; }>()
);

export const CreditCardsRemoveUnsavedCard = createAction(
    '[Credit cards] Remove unsaved card',
    props<{ token: string; }>(),
);

export const CreditCardsClearAllUnsavedCards = createAction(
    '[Credit cards] Clear all unsaved cards'
);

export const CreditCardsSetErrorValidationStatusToValidatingCards = createAction(
    '[Credit cards] Set error validation status to awaiting validation cards'
);

/**
 * Adyen PP setup actions
 */
export const CreditCardsAdyenAppendCVVNumber = createAction(
    '[Credit cards] Adyen append CVV number',
    props<{cardId: number | string; encryptedSecurityCode: string;}>()
);

export const CreditCardsAdyenInit = createAction(
    '[Credit cards] Adyen init',
);

export const CreditCardsAdyenConfigRequest = createAction(
    '[Credit cards] Adyen setup request',
    props<{locationNo: number; }>()
);

export const CreditCardsAdyenConfigSuccessRequest = createAction(
    '[Credit cards] Adyen setup success request',
    props<{locationNo: number; config: IAdyenSettingsResponse; }>()
);

export const CreditCardsAdyenConfigErrorRequest = createAction(
    '[Credit cards] Adyen setup error request',
    props<{locationNo: number; }>()
);

/**
 * Stripe PP setup actions
 */
export const CreditCardsStripeInit = createAction(
    '[Credit cards] Stripe init',
);

export const CreditCardsStripeConfigRequest = createAction(
    '[Credit cards] Stripe setup request',
    props<{locationNo: number; }>()
);

export const CreditCardsStripeConfigSuccessRequest = createAction(
    '[Credit cards] Stripe setup success request',
    props<{locationNo: number; config: IStripeSettingsResponse; }>()
);

export const CreditCardsStripeConfigErrorRequest = createAction(
    '[Credit cards] Adyen setup error request',
    props<{locationNo: number; }>()
);
