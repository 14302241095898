import { Injectable, Inject } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import * as actions from '../../../actions';
import * as selectors from '../../../selectors';

import * as State from '@shared/state/interface';
import * as Services from '@shared/core/services';
import * as Tokens from '@shared/core/tokens';

import * as StateModels from '../../../interface';

import { Observable } from 'rxjs';
import { switchMap, withLatestFrom, filter, take } from 'rxjs/operators';

@Injectable()
export class MemberBillingAddressModalEffects {
    @Effect({ dispatch: false }) public showBillingAddressModalForCard$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.SelectActiveCreditCardId
            ),
            filter(({ cardId }) => Boolean(cardId)),
            withLatestFrom(
                this._store.pipe(select(selectors.isMemberAuthorizedJWT)),
                this._store.pipe(select(selectors.getActiveCardDetails)),
                this._store.pipe(select(selectors.isCheckoutPage)),
            ),
            filter(([,,, isCheckoutPage]) => isCheckoutPage),
            switchMap(([, isAuthorized, cardDetails]) => this._store
                .pipe(
                    select(selectors.isOrderTypeValid(this._config)),
                    filter(isValid => isValid),
                    filter(() => isAuthorized && cardDetails && cardDetails?.BillingDetails == null && this._creditCardsService.isBillingAddressRequired),
                    take(1),
                    switchMap(() => {
                        // TODO - check visible modals
                        // trigger custom action instead of selectActiveCreditCardId - we need to control this before when payment
                        // step kicks in - not before, when default is selected

                        const modalParams: State.IModal = {
                            type: 'billing-details',
                            animate: null,
                        };
                        this._modalsService.show(modalParams);

                        return [];
                    })
                ))
        );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _store: Store<StateModels.IStateShared>,
        private _actions$: Actions,
        private _creditCardsService: Services.CreditCardsService,
        private _modalsService: Services.ModalsService,
    ) { }

}
