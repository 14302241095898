import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IStateShared, IPayment } from '../interface';
import * as Utils from '@shared/core/utils';

const state = createFeatureSelector<IStateShared, IPayment>('payment');

export const getPaymentState = createSelector(
    state,
    payment => payment
);

export const paymentHasErrors = createSelector(
    state,
    payment => payment.errors.length > 0
);

export const getPaymentStepsStatus = createSelector(
    state,
    payment => payment.paymentStepStatus
);

export const getPaymentStatus = createSelector(
    state,
    payment => {
        const p = payment;
        if (p.hasFailed === true) return 'failed';

        if (p.isPaying) {
            return 'pending';
        }

        if (p.hasSucceeded) {
            return 'success';
        }

        if (p.hasFailed) {
            if (p.data && p.data.Status === OLO.Enums.PAYMENT_STATUS.FAILED) return 'declined';

            return 'failed';
        }

        return null;

    }
);

export const isPaying = createSelector(
    state,
    payment => {
        const s = payment.paymentStepStatus;

        return s !== null && s !== 'failed' && s !== 'complete';
    }
);

export const isPaymentComplete = createSelector(
    state,
    payment => payment.paymentStepStatus === 'complete'
);

export const getPaymentErrors = createSelector(
    state,
    payment => payment.errors
);

export const isPaymentDisabledForPayments = createSelector(
    getPaymentStatus,
    isPaying,
    (paymentStatus, isPayingFlag) => (paymentStatus === 'pending' || paymentStatus === 'success') ||
            isPayingFlag
);

export const getPaymentErrorsMapped = createSelector(
    getPaymentErrors,
    getPaymentState,
    (errors, payment) => {
        if (payment.hasSucceeded || payment.paymentStepStatus === 'complete' || payment.data !== null && payment.data.Status === OLO.Enums.PAYMENT_STATUS.SUCCESS) {

            return Utils.Messages.mapErrors({ previousTransaction: 'Previous transaction available' })[0];
        }

        if (!errors.length) return null;

        /* Currently we handle only one error */
        return Utils.Messages.mapErrors({ processingPayment: 'We\'re having trouble processing payment. Please try again' })[0];
    }
);

export const getPaymentVendorService = createSelector(
    getPaymentState,
    paymentState => paymentState.paymentMethod?.vendorService || null
);

export const isPaymentPossible = createSelector(
    getPaymentState,
    (paymentState) => paymentState.hasSucceeded === false &&
    paymentState.data === null &&
    paymentState.paymentStepStatus !== 'complete' &&
    paymentState.paymentStepStatus !== 'payment_status_check'
);

export const isRedirectPaymentType = createSelector(
    getPaymentState,
    paymentState => paymentState.redirect?.vendorPayment === true,
);

export const isBrowserBasedPaymentMethod = createSelector(
    getPaymentState,
    paymentState => Boolean(paymentState.paymentMethod?.vendorService)
);

export const isPayingWithApplePay = createSelector(
    getPaymentState,
    (paymentState) => paymentState.paymentMethod?.vendorService === OLO.Enums.PAYMENT_SERVICE.APPLE_PAY
        && Boolean(paymentState.paymentMethod.applePaymentData)
);
