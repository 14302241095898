import { Numbers } from './numbers.utils';
import { CARDLESS_PAYMENT_TYPES } from '@shared/core/consts';

export class CreditCards {
    public static detectFormat(date: string): number {
        if (typeof date !== 'string') return null;

        const f1 = date.match(/^\d{2}-\d{2}-\d{2}$/gm); /* 12-31-24 */
        const f2 = date.match(/^\d{2}\/\d{2}$/gm); /* 01/24 */
        const f3 = date.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.?(\d{3}Z)?$/gm); /* 2014-01-02T23:22:33.333Z */
        const f4 = date.match(/^\d{2}-\d{2}-\d{4}$/gm); /* 12-31-2024 */
        const f5 = date.match(/^\d{1,2}\/\d{4}/); /* 1/2024 or 12/2024 */

        if (f1) return 0;
        if (f2) return 1;
        if (f3) return 2;
        if (f4) return 4;
        if (f5) return 5;

        return null;
    }

    /**
     * Converts any allowed date string to API date fromat
     *
     * @param {string} date date in any string format
     * @return {string} ```MM-DD-YYYY``` formated string where day is always 01, e.g. 12-01-2024
     */
    public static dateToApiFormat(date: string): string {
        if (typeof date !== 'string') return null;
        const format = CreditCards.detectFormat(date);

        let arrF1, arrF2: string[];
        switch (true) {
            case format === 0:
                arrF1 = date.split('-');
                arrF1[2] = '20' + arrF1[2];

                return arrF1.join('-');
            case format === 1:
                return date.replace('/', '-01-20');
            case format === 2:
                arrF2 = date.split('T')[0].split('-');

                return `${arrF2[1]}-01-${arrF2[0]}`;
            case format === 4:
                return date;
            case format === 5:
                arrF1 = date.split('/');
                arrF1[0] = Numbers.leadingZero(arrF1[0]);

                return arrF1.join('-01-');
            default:
                console.warn('Date format not supported', date);

                return null;
        }
    }

    public static dateToShortFormat(date: string): string {
        if (typeof date !== 'string') return null;
        const d: string = CreditCards.dateToApiFormat(date);

        return d && d.replace(/-\d{2}-/, '/') || null;
    }

    public static dateToShowFormat(date: string, separator: string = '/'): string {
        if (typeof date !== 'string') return null;
        const d: string = CreditCards.dateToApiFormat(date);
        const expDate = d ? d.replace(/-\d{2}-/, '/').split('/') : null;

        return expDate ? `${expDate[0]}${separator}${expDate[1].slice(2)}` : null;
    }

    /**
     * Converts any date string to custom format
     * @param {string} date string
     * @param {string=} format default MMYY
     * @returns {Nullable<string>} formatted date MMYY
     */
    public static dateToCustomFormat(date: string, format = 'MMYY'): Nullable<string> {
        if (typeof date !== 'string') return null;
        const d: string = CreditCards.dateToApiFormat(date);

        if (!d) {
            return null;
        }

        const splitted = d.split('-');
        const partial = {
            day: splitted[1],
            month: splitted[0],
            year: splitted[2],
            yearShort: splitted[2].replace(/^\d{2}/, ''),
        };

        return format.replace('YYYY', partial.year).replace('YY', partial.yearShort)
            .replace('DD', partial.day)
            .replace('MM', partial.month);
    }

    public static dateToISOString(date: string): string {
        /* Will return edge date to last milisecond */
        if (typeof date !== 'string') return null;
        const isApiFormat = CreditCards.detectFormat(date) === 0 || CreditCards.detectFormat(date) === 4;
        if (!isApiFormat) return null;

        const apiDate = CreditCards.dateToApiFormat(date);
        const d = apiDate.split('-');
        const lastDay = new Date(+`${d[2]}`, +d[0], 0).getDate();

        return `${d[2]}-${d[0]}-${lastDay < 10 ? `0${lastDay}` : `${lastDay}`}T23:59:59.999Z`;
    }

    /* https://www.freeformatter.com/credit-card-number-generator-validator.html */
    public static readonly cardPatterns: OLO.Common.ICreditCardPatterns = {
        Visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
        MasterCard: /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/,
        AmericanExpress: /^3[47][0-9]{13}$/,
        DinersClub: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
        Discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
        JCB: /^(?:2131|1800|35\d{3})\d{11}$/,
    };

    public static detectCardType(cardNo: string | number): OLO.Enums.CREDIT_CARD_TYPES {
        cardNo = typeof cardNo === 'number' ? `${cardNo}` : cardNo;

        if (typeof cardNo !== 'string' || !cardNo || !cardNo.replace) return null;

        cardNo = cardNo.replace(/\D/gm, '');

        switch (true) {
            case CreditCards.cardPatterns.Visa.test(cardNo):
                return OLO.Enums.CREDIT_CARD_TYPES.VISA;

            case CreditCards.cardPatterns.MasterCard.test(cardNo):
                return OLO.Enums.CREDIT_CARD_TYPES.MASTER_CARD;

            case CreditCards.cardPatterns.AmericanExpress.test(cardNo):
                return OLO.Enums.CREDIT_CARD_TYPES.AMERICAN_EXPRESS;

            case CreditCards.cardPatterns.DinersClub.test(cardNo):
                return OLO.Enums.CREDIT_CARD_TYPES.DINERS_CLUB;

            case CreditCards.cardPatterns.Discover.test(cardNo):
                return OLO.Enums.CREDIT_CARD_TYPES.DISCOVER;

            case CreditCards.cardPatterns.JCB.test(cardNo):
                return OLO.Enums.CREDIT_CARD_TYPES.JCB;

            default:
                return null;
        }
    }

    public static mapEnumToTypeName(enumId: number | OLO.Enums.CREDIT_CARD_TYPES): string {
        switch (enumId) {
            case OLO.Enums.CREDIT_CARD_TYPES.VISA:
                return 'Visa';
            case OLO.Enums.CREDIT_CARD_TYPES.MASTER_CARD:
                return 'Master Card';
            case OLO.Enums.CREDIT_CARD_TYPES.AMERICAN_EXPRESS:
                return 'American Express';
            case OLO.Enums.CREDIT_CARD_TYPES.DINERS_CLUB:
                return 'Diners Club';
            case OLO.Enums.CREDIT_CARD_TYPES.DISCOVER:
                return 'Discover';
            case OLO.Enums.CREDIT_CARD_TYPES.JCB:
                return 'JCB';
            default:
                return 'N/A';
        }
    }

    /**
     * Converts brand string to credit card type
     * @param {string} brand name
     * @return {OLO.Enums.CREDIT_CARD_TYPES} enum
     */
    public static mapBrandToEnum(brand: string): OLO.Enums.CREDIT_CARD_TYPES {
        const b = brand.toLowerCase();

        switch (true) {
            case b === 'visa':
                return OLO.Enums.CREDIT_CARD_TYPES.VISA;
            case b === 'mastercard':
                return OLO.Enums.CREDIT_CARD_TYPES.MASTER_CARD;
            case b === 'amex':
                return OLO.Enums.CREDIT_CARD_TYPES.AMERICAN_EXPRESS;
            case b === 'diners':
                return OLO.Enums.CREDIT_CARD_TYPES.DINERS_CLUB;
            case b === 'discover':
                return OLO.Enums.CREDIT_CARD_TYPES.DISCOVER;
            case b === 'jcb':
                return OLO.Enums.CREDIT_CARD_TYPES.JCB;
            default:
                return null;
        }

    }

    public static processCardNumber(cardNo: number | string): string {
        return (`${cardNo}`).replace(/[^0-9]/gi, '');
    }

    public static validateCardNumber(cardNo: number | string, preprocessString: boolean = true): boolean {
        // /* https://stackoverflow.com/questions/9315647/regex-credit-card-number-tests */
        // /* https://www.regular-expressions.info/creditcard.html */
        const REGEX = /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;
        const REGEX_END_OF_STRING = /[^(0-9)]+$/;
        const cardNoToProcess: string = preprocessString ? CreditCards.processCardNumber(cardNo) : `${cardNo}`;

        return REGEX.test(cardNoToProcess) && REGEX_END_OF_STRING.test(cardNoToProcess) === false;
    }
    /**
     * Converts countryId in billing details to ISO ALPHA 2 CODE
     * @param {Nullable<OLO.CreditCards.CreditCardBillingDetails>} billingDetails
     * @param {OLO.DTO.LoyaltyAppCountryAssignmentModel[]} countries
     * @returns billingDetails
     */
    public static normalizeBillingDetailsCountryCode(
        billingDetails: Nullable<OLO.CreditCards.CreditCardBillingDetails>,
        countries: OLO.Common.ILoyaltyAppCountryAssignmentModel[],
    ): OLO.CreditCards.CreditCardBillingDetails {
        if (billingDetails) {
            billingDetails.CountryIso2Code = countries.find((obj) => obj.Id === (billingDetails.CountryIso2Code as unknown as number)).IsoAlpha2Code;
        }

        return billingDetails;
    }

    public static remapRedirectCardDetails(
        cardData: OLO.CreditCards.ICreditCardRedirectDetails
    ): OLO.CreditCards.ICreditCardDetails {
        return {
            cardHolderName: cardData.CardHolderName,
            cardNumber: cardData.CardNumber,
            cvv: cardData.Cvc2,
            expiryDate: `${cardData.ExpiryMonth}/${cardData.ExpiryYear}`,
            isDefaultPaymentMethod: cardData.isDefaultPaymentMethod || false,
            saveCard: cardData.saveCard || false,
            billingDetails: cardData?.BillingDetails ? {
                Street: cardData.BillingDetails.Street ?? null,
                HouseNumber: cardData.BillingDetails.HouseNumber ?? null,
                City: cardData.BillingDetails.City ?? null,
                StateOrProvince: cardData.BillingDetails.StateOrProvince ?? null,
                CountryIso2Code: cardData.BillingDetails.CountryIso2Code ?? null,
                PostalCode: cardData.BillingDetails.PostalCode ?? null,
            } : null
        };
    }

    public static isCardlessType(id: number | string): boolean {
        return CARDLESS_PAYMENT_TYPES.includes(id as typeof CARDLESS_PAYMENT_TYPES[number]);
    }

}
