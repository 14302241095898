import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

import { Observable, throwError } from 'rxjs';

import { map, catchError, flatMap, switchMap } from 'rxjs/operators';
import { ICardConnectSettingsResponse } from '@shared/state';
import { CardConnectPaymentProviderMapper } from '@shared/core/mappers/paymentProviders/card-connect.payment-provider.shared.mapper';

@Injectable({
    providedIn: 'root',
})
export class CardConnectPaymentProviderService {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public httpClient: HttpClient,
    ) { }

    public getCardConnectSettings(locationNo: number): Observable<ICardConnectSettingsResponse> {
        return this.httpClient
            .get<APIv3.CardConnectSettingsResponse>(`${Utils.HTTP.switchApi(this.config.api.base)}/Payments/cardConnect/settings/${locationNo}`)
            .pipe(
                map((response: APIv3.CardConnectSettingsResponse) => CardConnectPaymentProviderMapper.mapGetCardConnectSettings(response)),
            );
    }

    public cardConnectSecureCard(apiUrl: string, cardNo: number | string): Observable<PPCardConnect.ISecureResponse> {
        return this.httpClient
            .get(`${apiUrl}/cardsecure/cs?action=CE&data=${Utils.CreditCards.processCardNumber(cardNo)}&type=xml`, {
                responseType: 'text',
                headers: new HttpHeaders({
                    'Content-Type': 'text/xml',
                    'Accept': 'text/xml'
                }),

            }).pipe(
                map((response: string) => CardConnectPaymentProviderMapper.mapCardConnectSecureCard(response)),
            );
    }

    public requestCardToken(cardNumber: string, locationNo: number = null): Observable<PPCardConnect.ISecureResponse> {
        return this.getCardConnectSettings(locationNo)
            .pipe(
                flatMap((settings: APIv3.CardConnectSettingsResponse) => this.cardConnectSecureCard(settings.ApiUrl, cardNumber)),
                map(response => {
                    if (response.cardsecure.action === 'ER') throw response;

                    return response;
                }),
                catchError(ex => throwError(ex))
            );
    }

    public requestCardToken2$(cardDetails: OLO.CreditCards.ICreditCardDetails, locationNo: number = null): Observable<PPCardConnect.ResponseToken> {
        return this.getCardConnectSettings(locationNo).pipe(
            switchMap((settings: APIv3.CardConnectSettingsResponse) => this._cardConnectSecureCard2$(settings.ApiUrl, cardDetails)),
            map((response) => {
                if (response.errorcode !== 0) throw response;

                return response;
            }),
            catchError((ex) => throwError(ex)),
        );
    }

    protected _cardConnectSecureCard2$(apiUrl: string, cardDetails: OLO.CreditCards.ICreditCardDetails): Observable<PPCardConnect.ResponseToken> {
        const requestDetails = CardConnectPaymentProviderMapper.mapCardDetails(cardDetails);

        return this.httpClient
            .post<PPCardConnect.ResponseToken>(`${apiUrl}/cardsecure/api/v1/ccn/tokenize`, requestDetails)
            .pipe(map((response) => CardConnectPaymentProviderMapper.mapSecuredTokenResponse(response)));
    }
}
