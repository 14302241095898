import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Store, select } from '@ngrx/store';
import * as State from '@shared/state';

import * as selectors from '@shared/state/selectors';
import * as Services from '@shared/core/services';
import { Controllers } from '@shared/core/controllers';

import { Observable, of } from 'rxjs';
import { filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ConfirmationGuard implements CanActivate, CanActivateChild {
    constructor(
        private _controllers: Controllers,
        private _onlineOrdersService: Services.OnlineOrdersService,
        private _routeService: Services.RouteService,
        private _store: Store<State.IStateShared>,
    ) { }

    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this._controllers.routes.extractOrderConfirmationQueryParams$()
            .pipe(
                withLatestFrom(this._controllers.members.isAuthorized$()),
                take(1),
                switchMap(([queryParams, isAuthorized]) => {
                    if (!queryParams) {
                        console.warn('unable to extract orderId & locationNo', queryParams);

                        this._routeService.navigate(['/404']);

                        return of(false);
                    }

                    if (isAuthorized) {
                        this._onlineOrdersService.requestHistoryOrder(queryParams.orderId);

                        return this._store.pipe(
                            select(selectors.getHistoryOrder(queryParams.orderId)),
                            filter(order => order && order.isDownloading === false),
                            map(() => true)
                        );
                    }

                    /* Get cached order for guest member */
                    const cachedOrder = this._onlineOrdersService.getHistoryOrderFromStorage(queryParams.orderId);
                    if (!cachedOrder) {
                        this._routeService.navigate(['/membership-only']);

                        return of(false);
                    }

                    this._onlineOrdersService.requestOrderStatus(queryParams.orderId, true);

                    return of(true);
                })
            );
    }

    public canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivate(next, state);
    }
}
