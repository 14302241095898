export type MODAL_TYPE_FOR_ACTIVE_ORDER_PROMPT = 'active-order-prompt';
export type MODAL_TYPE_FOR_PROMPT = 'prompt';
export type MODAL_TYPE_FOR_INFO = 'info';
export type MODAL_TYPE_FOR_ALERT = 'alert';
export type MODAL_TYPE_FOR_LOADING = 'loading';
export type MODAL_TYPE_FOR_LOCATION_OPEN_HOURS = 'location-open-hours';
export type MODAL_TYPE_FOR_PRODUCT = 'product';
export type MODAL_TYPE_FOR_HISTORY_ORDER = 'history-order';
export type MODAL_TYPE_FOR_TRANSACTION = 'transaction';
export type MODAL_TYPE_FOR_REORDER = 'reorder';
export type MODAL_TYPE_FOR_AUTH = 'auth';
export type MODAL_TYPE_FOR_REWARD = 'reward';
export type MODAL_TYPE_FOR_CREDIT_CARD_ADD = 'credit-card-add';
export type MODAL_TYPE_FOR_MEMBER_CARD = 'member-card';
export type MODAL_TYPE_FOR_ORDER_TYPES = 'order-types';
export type MODAL_TYPE_FOR_DELIVERY_NOT_AVAILABLE = 'delivery-not-available';
export type MODAL_TYPE_FOR_DELIVERY_ADDRESS = 'delivery-address';
export type MODAL_TYPE_FOR_BILLING_DETAILS = 'billing-details';
export type MODAL_TYPE_FOR_DINE_IN = 'dine-in';
export type MODAL_TYPE_FOR_SCHEDULE = 'schedule';
export type MODAL_TYPE_FOR_DELETE_PROFILE = 'delete-profile';
export type MODAL_TYPE_FOR_ADD_VOUCHER = 'add-voucher';
export type MODAL_TYPE_FOR_ADYEN_CVV = 'adyen-cvv';

export type MODAL_TYPE = MODAL_TYPE_FOR_ACTIVE_ORDER_PROMPT
| MODAL_TYPE_FOR_PROMPT
| MODAL_TYPE_FOR_INFO
| MODAL_TYPE_FOR_ALERT
| MODAL_TYPE_FOR_LOADING
| MODAL_TYPE_FOR_LOCATION_OPEN_HOURS
| MODAL_TYPE_FOR_PRODUCT
| MODAL_TYPE_FOR_HISTORY_ORDER
| MODAL_TYPE_FOR_TRANSACTION
| MODAL_TYPE_FOR_REORDER
| MODAL_TYPE_FOR_AUTH
| MODAL_TYPE_FOR_REWARD
| MODAL_TYPE_FOR_CREDIT_CARD_ADD
| MODAL_TYPE_FOR_MEMBER_CARD
| MODAL_TYPE_FOR_ORDER_TYPES
| MODAL_TYPE_FOR_SCHEDULE
| MODAL_TYPE_FOR_DELIVERY_NOT_AVAILABLE
| MODAL_TYPE_FOR_DELIVERY_ADDRESS
| MODAL_TYPE_FOR_BILLING_DETAILS
| MODAL_TYPE_FOR_DINE_IN
| MODAL_TYPE_FOR_DELETE_PROFILE
| MODAL_TYPE_FOR_ADD_VOUCHER
| MODAL_TYPE_FOR_ADYEN_CVV
| OLO.Types.MENU_FLOW_TYPES;

export type MODAL_FEEL = 'brand-accent';

export type MODAL_ICON = 'warning' | 'info';

export enum MODAL_ANIMATION {
    IN = 'in',
    OUT = 'out',
    NONE = 'none',
}
export interface IModalState {
    modals?: IModal[];
}

export interface IModal {
    id?: number;
    animate?: MODAL_ANIMATION;
    type: MODAL_TYPE;
    memberId?: number;
    locationNo?: number;
    menuFlowId?: number;
    productId?: number;
    orderId?: number;
    transactionId?: number;
    params?: IModalParams;
    isLoading?: boolean;
    feel?: MODAL_FEEL;
}

export interface IModalSwap {
    animate?: MODAL_ANIMATION;
    type?: MODAL_TYPE;
    locationNo?: number;
    menuFlowId?: number;
    productId?: number;
    orderId?: number;
    params?: IModalParams;
    isLoading?: boolean;
}

export interface IModalParams {
    preTitle?: string;
    title?: string;
    body?: string;
    buttonLabel?: string;
    [key: string]: any;
}
