export class DateTimeRounder {
    private _tempDate: Date;

    constructor(
        public date: Date,
        public direction: 'up' | 'down',
        /** Default [[TickIndicator]] for [[DateTimeRounder.round]] method */
        public tickIndicator: TickIndicator = 15
    ) {
        this._tempDate = new Date(date.getTime());
    }

    private _firstAvailableFullHour(initialDate: Date): Date {
        return new Date(new Date(initialDate.getTime() + 1000 * 60 * 60).setMinutes(0));
    }

    /**
     * Based on [[tickIndicator]] value, selects proper rounding method
     * @returns {Date}
     */
    public round(): Date | never {
        switch(this.tickIndicator) {
            case 15:
                return this.roundByQuarter();
            case 30:
                return this.roundByHalfHour();
            case 60:
                return this.roundByHour();
            default:
                throw new Error(`Invalid nextTick indicator value: ${this.tickIndicator}. Allowed values: 15, 30, 60.`);
        }
    }

    public roundByQuarter(): Date {
        const minutes = this._tempDate.getMinutes();

        let newMinutes: number;
        switch (true) {
            case minutes < 15:
                newMinutes = this.direction === 'up' ? 15 : 0;
                break;

            case minutes >= 15 && minutes < 30:
                newMinutes = this.direction === 'up' ? 30 : 15;
                break;

            case minutes >= 30 && minutes < 45:
                newMinutes = this.direction === 'up' ? 45 : 30;
                break;

            case minutes >= 45:
                newMinutes = this.direction === 'up' ? 60 : 45;
                break;
        }
        if (newMinutes === 60) {
            return this._firstAvailableFullHour(this._tempDate);
        }

        return new Date(this._tempDate.setMinutes(newMinutes));
    }

    public roundByHalfHour(): Date {
        const minutes = this._tempDate.getMinutes();

        let newMinutes: number;
        switch (true) {
            case minutes === 0:
                newMinutes = 0;
                break;
            case minutes === 30:
                newMinutes = 30;
                break;
            case minutes < 30:
                newMinutes = this.direction === 'up' ? 30 : 0;
                break;

            case minutes >= 30:
                newMinutes = this.direction === 'up' ? 60 : 0;
                break;
        }

        if (newMinutes === 60) {
            return this._firstAvailableFullHour(this._tempDate);
        }

        return new Date(this._tempDate.setMinutes(newMinutes));
    }

    public roundByHour(): Date {
        const minutes = this._tempDate.getMinutes();

        let newMinutes: number;
        switch (true) {
            case minutes === 0:
                newMinutes = 0;
                break;
            case minutes < 30:
                newMinutes = this.direction === 'up' ? 60 : 0;
                break;

            case minutes >= 30:
                newMinutes = this.direction === 'up' ? 60 : 0;
                break;
        }

        return new Date(this._tempDate.setMinutes(newMinutes));
    }
}
