import { Objects } from '@shared/core/utils/objects.utils';
import { environment } from '../environments/environment';
const defaultConfig: IConfig = {
    membersCanDeleteAccounts: true,
    signUpRewardsAccountLinking: {
        enabled: false,
        introText: 'Do you have a Rewards Account? Select the “Link rewards account” below to sign up using this account and start earning rewards on online.',
        headerText: 'Rewards',
        descriptionText: 'Link your rewards account. Enter your account number found in app profile page & associated email address',
        cardNumberLabelText: 'Account number ( 16 digits ):',
    },
    defaultMemberGroupId: null,
    energyUnits: OLO.Enums.ENERGY_UNITS.CALS,
    disclaimer: null,
    accountCharge: {
        enabled: false,
        balanceDisplayThreshold: 100,
    },
    api: {
        base: 'https://taskoloapidev.xchangefusion.com/api/v1',
        key: '94a5dfd6-18be-4ae8-8a1c-292ae6104d11'
    },
    appMode: 0,
    payments: {
        currencyCode: 'AUD',
        payInStore: true,
        baseProvider: 3,
        redirectType: true,
        googlePay: {
            enabled: true,
            testMode: false,
            apiVersion: 2,
            apiVersionMinor: 0,
            merchantName: null,
            gateway: 'windcave',
            gatewayMerchantId: null,
            allowedCardNetworks: ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA'],
            allowedCardAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
        },
        applePay: {
            enabled: true,
            merchantId: 'test',
            displayName: '',
            initiativeContext: '',
            apiVersion: 3,
            supportedNetworks: ['visa', 'masterCard', 'jcb', 'discover', 'amex'],
            merchantCapability: ['supports3DS', 'supportsCredit', 'supportsDebit', 'supportsEMV']
        }
    },
    demoMode: true,
    LoyaltyAppId: 1,
    venue: {
        id: 1,
        name: '2400'
    },
    links: [
        {
            id: 1,
            name: 'Contact Us',
            url: 'https://tasksoftware.com.au/contact-us'
        },
        {
            id: 2,
            name: 'User Agreement',
            url: 'https://tasksoftware.com.au/about-us/our-story'
        },
        {
            id: 3,
            name: 'Privacy Policy',
            url: 'https://tasksoftware.com.au/about-us/our-culture'
        }
    ],
    termsAndConditionsUrl: 'https://tasksoftware.com.au/about-us/our-story',
    localization: {
        country: 'AU',
        units: 'metric',
        currency: 'USD', // 'USD' | 'PLN' | 'EUR'
        locale: 'pl-PL' // 'en-AU' | 'pl-PL' | 'en-US' | 'en-NZ' | 'es-US'
    },
    saleName: 'TASK',
    showSpecialInstructionsForProducts: true,
    showSpecialInstructionsForMenuFlows: true,
    title: null,
    sessionCookieExpDays: 30,
    minimumAgeToSignUp: 14,

    futureOrders: false,
    collectionTypes: {
        pickup: {
            enabled: true,
            startBufferMins: 0,
            orderTimeoutBufferMins: 15,
            nextTick: 15,
            orderTypeIds: [],
        },
        delivery: {
            enabled: true,
            isDefault: true,
            orderTypeId: 99,
            radius: 5,
        },
        dineIn: {
            enabled: true,
            locationSpecific: true,
            dineInTable: {
                orderTypeId: 3,
                modalIcon: null,
                modalPreTitle: 'DINE IN ORDER',
                modalDescription: [
                    'Enter your table number , choose delicious Items',
                    'from our menu and we’ll bring your order to you!'
                ],
                modalDescriptionSelected: [
                    'Start your order, choose some delicious Items from',
                    'our menu and we\'ll bring your order to you!'
                ]
            },
            dineInBuzzer: {
                orderTypeId: 4,
                modalIcon: 'phone',
                modalPreTitle: 'DINE IN ORDER',
                modalDescription: [
                    'Start your order, choose some delicious Items from',
                    'our menu and we’ll send you an sms message',
                    'When your order is ready to collect from our kitchen'
                ]
            }
        }
    },
    checkoutDisclaimers: {
        enabled: false,
    },
    images: {
        defaultLocationThumb: '/assets/images/img.location-thumb.jpg',
    },
    geolocation: true,
    google: {
        analytics: [
            {
                id: 'UA-160404134-2',
                name: 'test'
            },
            {
                id: 'UA-160404134-3',
            },
        ],
        maps: {
            apiKey: '',
            libraries: ['places'],
            types: null,
            defaults: {
                center: { lat: 33.6294864, lng: -117.71724360000002 },
                zoom: 10,
                styles: [],
            },
            componentRestrictions: {
                country: 'au',
            },
            searchDistance: 30,
            markerWidth: 50,
            markerHeight: 69,
            markerSelectedWidth: 75,
            markerSelectedHeight: 119,
            markerDownsizeFactor: 2,
        },
    },
    modals: {
        animateTimeout: 500,
        bgClickClose: true,
        preventGlobalScroll: true,
    },

    onlineOrders: {
        sendAutoConfirmationEmail: true,
        sendAutoReceiptEmail: false,
        allowModifiers: false,
        allowClosedLocationOrders: true,
        OnlineOrderType: 0,
        discounts: {
            enable: false,
            value: null
        }
    },

    onlineMenu: {
        description: {
            startAt: 0,
            limit: 62,
            ellipsis: '...',
            completeWords: false
        },
        wizzardDescription: {
            startAt: 0,
            limit: 82,
            ellipsis: '...',
            completeWords: false
        }
    },

    predownloadImages: {
        forLocations: false,
        forVenue: false
    },
    unverifiedEmailQuickLogin: false,

    passwordRules: {
        minLength: 6,
        upperCaseCount: 1,
        lowerCaseCount: 1,
        digitsCount: 1,
        specialCharsCount: 1,
        allowWhiteSpaces: false,
    },
    ui: {
        checkoutMaxCartItemsLines: 1,
        searchPlaceholderText: 'Search by suburb, city, zip',
        heroBackground: {
            topGradient: true,
            overlay: 'half-angled'
        },
        loyalty: {
            freeProductTile: {
                feel: 'gold',
                titleInverted: true
            },
            heroText: [
                'Dount King rewards.',
                'Let\'s get started, login or create an account.'
            ],
            layout: 'row',
            cta: {
                title: 'Join now & start earning rewards',
                description: [
                    'So you want in hey? Well, it ain’t that easy. You can’t just magically download the app and',
                    'register your details and expect to be granted entry into our super exclusive Vibe Club.',
                    'Wait. Actually, that’s exactly all you need to do. So go and do it so you can enjoy free',
                    'Boost stuff and juicy deals!'
                ],
                sectionFeel: 'primary',
                btnFeel: 'secondary',
                btnInverted: true,
                titleInverted: true
            }
        },
        memberVerification: {
            landing: {
                header: 'Welcome',
                body: 'To complete registration<br />please click on the button below',
                button: 'verify account',
                sub: 'If you did not create an account using this address,<br />please ignore this email.'
            },
            success: {
                header: 'Success!',
                body: 'Your email has been verified. You can now sign in',
            },
            failure: {
                header: 'Sorry!',
                body: 'The confirmation token has expired<br />or it was used already',
            },
            failureVerify: {
                header: 'Something went wrong!',
                body: 'Your email address has already been verified',
            },
        },
        forgotPassword: {
            landing: {
                header: 'Reset Password',
                body: 'Enter a new password below',
                button: 'Reset password',
            },
            success: {
                header: 'Success!',
                body: 'Your password has been successfully updated',
            },
            failure: {
                header: 'Sorry!',
                body: 'The reset password token has expired<br />or it was used already',
            },
            failureChange: {
                header: 'Sorry!',
                body: 'There was an error while changing your password',
            },
        }
    },
    birthdayRewards: true,
    memberCard: {
        minLength: 10,
        maxLength: 10,
    },
    isLiveViewEnabled: false
};
const __CONFIG = (window as any).__CONFIG;

export const CONFIG: IConfig = {
    ...defaultConfig,
    ...JSON.parse(JSON.stringify(__CONFIG)),
};

CONFIG.payments.googlePay = {
    ...defaultConfig.payments.googlePay,
    ...__CONFIG.payments.googlePay,
};
CONFIG.payments.applePay = {
    ...defaultConfig.payments.applePay,
    ...__CONFIG.payments.applePay,
};

/* Check api props */
if (environment.production === true && (!__CONFIG?.api?.base || !__CONFIG?.api?.key)) {
    console.error('SETUP ISSUE! Api not configured in config.js file. Setting fallback to dev API and switching to DEMO mode');
    CONFIG.api.base = defaultConfig.api.base;
    CONFIG.api.key = defaultConfig.api.key;
    CONFIG.demoMode = true;
}

/* Fix problem with routes */
if (__CONFIG.venue && (!__CONFIG.venue?.name || !__CONFIG.venue?.id)) {
    CONFIG.venue = null;
}

if (CONFIG.demoMode === true) {
    console.warn('DEMO MODE - payments are disabled');
}

/* Lock config object to prevent from modifying properties */
Objects.uberDeepFreez(CONFIG);

(window as any).__CONFIG = undefined; /* Cleanup */
