import { Injectable, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { StripeInitResult, StripeTargetElementsList, ElementsConfiguration } from '@stripe/stripe-js';
import * as selectors from '@shared/state/selectors';

import * as State from '@shared/state';
import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';
import * as Services from '@shared/core/services';

import { combineLatest, Observable, of } from 'rxjs';
import { filter, take, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PaymentController {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _store: Store<State.IStateShared>,
        private _paymentService: Services.PaymentsService,
        private _creditCardsService: Services.CreditCardsService
    ) { }

    public get paymentProcessRequiresAddressDetails(): boolean {
        return this._creditCardsService.isBillingAddressRequired;
    }

    public isBillingAddressRequired$(): Observable<boolean> {
        return this._store
            .pipe(
                select(
                    selectors.isGuestMember
                ),
                map(isGuest => this.paymentProcessRequiresAddressDetails && isGuest)
            );
    }

    /**
     * Assert if any payment provider is configured and users can pay with credit cards
     */
     public get isBaseProviderConfigured(): boolean {
        return this._paymentService.isBaseProviderConfigured;
    }

    /**
     * Assert if configured payment provider is a 'common' type - making payments and adding cards are done via multiple http calls to the api
     * @return {boolean} boolean
     */
    public get isCommonType(): boolean {
        return this._paymentService.isCommonType;
    }

    /**
     * Assert if configured payment provider is a 'redirect' type - when making payments or adding cards, user gets redirected to the third party websites for verification
     * @return {boolean} boolean
     */
    public get isRedirectType(): boolean {
        return this._paymentService.isRedirectType;
    }

    /**
     * Assert if configured payment provider is a 'custom form' type - when making payments or adding cards, third party payment form is served instead of the internal one
     * @return {boolean} boolean
     */
    public get isProviderFormType(): boolean {
        return this._paymentService.isProviderFormType;
    }

    /**
     * Assert if google pay is configured
     * @return {boolean} boolean
     */
    public get isGooglePayEnabled(): boolean {
        return this._paymentService.isGooglePayEnabled;
    }

    /**
     * Assert if apple pay is configured and environment allows Apple payments
     * @return {boolean} boolean
     */
    public get isApplePayEnabled(): boolean {
        return this._paymentService.isApplePayEnabled;
    }

    public getPaymentFormType$(): Observable<Nullable<OLO.Enums.PAYMENT_FORM_TYPE>> {
        switch (true) {
            case this.isCommonType:
                return of(OLO.Enums.PAYMENT_FORM_TYPE.COMMON);
            case this.isRedirectType:
                return of(OLO.Enums.PAYMENT_FORM_TYPE.REDIRECT);
            case this.isProviderFormType:
                return of(OLO.Enums.PAYMENT_FORM_TYPE.PROVIDER_FORM);
            default:
                console.error('Unsupported payment form type');

                return of(null);
        }
    }

    public getPaymentFormTypeMapped$(): Observable<Nullable<OLO.Types.PAYMENT_METHOD_FORM_TYPE>> {
        return this.getPaymentFormType$()
            .pipe(
                map(Utils.PaymentForms.mapEnumFormType)
            );
    }

    public get isRedirectClickPaymentEnabled(): boolean {
        return this._paymentService.windcavePaymentProviderService.isWindcaveConfigured;
    }

    public isApplePayPaymentMethodSelected$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isApplePayPaymentMethodSelected),
            );
    }

    public isGooglePayPaymentMethodSelected$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isGooglePayPaymentMethodSelected),
            );
    }

    public isVendorPaymentMethodSelected$(): Observable<boolean> {
        return combineLatest(
            this.isApplePayPaymentMethodSelected$(),
            this.isGooglePayPaymentMethodSelected$()
        ).pipe(
            map(result => result.includes(true))
        );
    }

    public initAdyenForm(targetHmtl: HTMLElement | string, configuration?: Adyen.Configuration): void {
        this._store
            .pipe(
                select(selectors.getAdyenLocationConfig),
                filter(state => state.isDownloading === false && state.hasSucceeded === true),
                take(1)
            ).subscribe(state => {
                this._paymentService.adyenPaymentProviderService.setupForm(state.data, targetHmtl, configuration);
            });
    }

    public isPaymentMethodSelected$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isSelectedPaymentMethodValid)
            );
    }

    public destroyAdyenForm(): void {
        return this._paymentService.adyenPaymentProviderService.destroyForm();
    }

    public async initStripeForm(targetHmtlElementsList: StripeTargetElementsList, configuration?: ElementsConfiguration): Promise<StripeInitResult> {
        return new Promise(resolve => {
            this._store
                .pipe(
                    select(selectors.getStripeLocationConfig),
                    filter(state => state.isDownloading === false && state.hasSucceeded === true),
                    take(1)
                ).subscribe(state => {
                    resolve(this._paymentService.stripePaymentProviderService.setupForm(state.data, targetHmtlElementsList, configuration));
                });
        });
    }

    public destroyStripeForm(): void {
        return this._paymentService.stripePaymentProviderService.destroyForm();
    }

    public getErrors$(): Observable<State.IPaymentError[]> {
        return this._store
            .pipe(
                select(selectors.getPaymentErrors)
            );
    }

    public getMappedError$(): Observable<OLO.Components.IMappedMessage> {
        return this._store
            .pipe(
                select(selectors.getPaymentErrorsMapped)
            );
    }

    public getMappedRecalcError$(): Observable<OLO.Components.IMappedMessage> {
        return this._store
            .pipe(
                select(selectors.hasRecalculateFailedErrorMapped),
            );
    }

    public isPaying$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isPaying)
            );
    }

    public isPaymentComplete$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isPaymentComplete)
            );
    }

    public paymentProvider(): OLO.Enums.PAYMENT_PROVIDER {
        return this._config.payments.baseProvider;
    }

    public resetPaymentFlow(): void {
        this._paymentService.resetPaymentFlow();
    }
}
