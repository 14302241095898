import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store, select } from '@ngrx/store';

import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';
import * as State from '@shared/state/interface';

import { CryptoService } from './crypto.shared.service';
import { ConvergePaymentProviderService } from './paymentProviders/converge.payment-provider.shared.service';
import { CardConnectPaymentProviderService } from './paymentProviders/card-connect.payment-provider.shared.service';
import { PaymentExpressPaymentProviderService } from './paymentProviders/payment-express.payment-provider.shared.service';
import { FatZebraPaymentProviderService } from './paymentProviders/fat-zebra.payment-provider.shared.service';
import { FatZebra3DSPaymentProviderService } from './paymentProviders/fat-zebra-3ds.payment-provider.shared.service';
import { AdyenPaymentProviderService } from './paymentProviders/adyen.payment-provider.shared.service';
import { StripePaymentProviderService } from './paymentProviders/stripe.payment-provider.shared.service';
import { GooglePayPaymentProviderService } from './paymentProviders/google-pay.payment-provider.shared.service';
import { ApplePayPaymentProviderService } from './paymentProviders/apple-pay.payment-provider.shared.service';
import { WindcavePaymentProviderService } from './paymentProviders/windcave.payment-provider.shared.service';

import * as actions from '@shared/state/actions';
import * as selectors from '@shared/state/selectors';

import {
    IExecuteAccountChargeResponse,
    IExecuteOnlineOrderAccountChargeModel,
    IExecutePaymentModel,
    IExecutePaymentResponse,
    IGetTransactionResponse
} from '@shared/state/interface';
import { PaymentsMapper } from '@shared/core/mappers/payments.shared.mapper';

import { Observable, throwError } from 'rxjs';
import { catchError, flatMap, map, filter, take, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class PaymentsService {
    constructor(
        protected _cryptoService: CryptoService,
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        public httpClient: HttpClient,
        public store: Store<State.IStateShared>,
        public convergePaymentProviderService: ConvergePaymentProviderService,
        public cardConnectPaymentProviderService: CardConnectPaymentProviderService,
        public paymentExpressPaymentProviderService: PaymentExpressPaymentProviderService,
        public fatZebraPaymentProviderService: FatZebraPaymentProviderService,
        public fatZebra3DSPaymentProviderService: FatZebra3DSPaymentProviderService,
        public adyenPaymentProviderService: AdyenPaymentProviderService,
        public stripePaymentProviderService: StripePaymentProviderService,
        public googlePayPaymentProviderService: GooglePayPaymentProviderService,
        public applePayPaymentProviderService: ApplePayPaymentProviderService,
        public windcavePaymentProviderService: WindcavePaymentProviderService,
    ) {
    }

    /**
     * Assert if any payment provider is configured and users can pay with credit cards
     */
    public get isBaseProviderConfigured(): boolean {
        return this._config.payments.baseProvider != null;
    }

    /**
     * Assert if configured payment provider is a 'common' type - making payments and adding cards are done via multiple http calls to the api
     * @return {boolean} boolean
     */
    public get isCommonType(): boolean {
        const providers = [OLO.Enums.PAYMENT_PROVIDER.CARD_CONNECT, OLO.Enums.PAYMENT_PROVIDER.CONVERGE];

        return providers.includes(this._config.payments.baseProvider);
    }

    /**
     * Assert if configured payment provider is a 'redirect' type - when making payments or adding cards, user gets redirected to the third party websites for verification
     * @return {boolean} boolean
     */
    public get isRedirectType(): boolean {
        const providers = [OLO.Enums.PAYMENT_PROVIDER.PAYMENT_EXPRESS, OLO.Enums.PAYMENT_PROVIDER.FAT_ZEBRA];

        return providers.includes(this._config.payments.baseProvider);
    }

    /**
     * Assert if configured payment provider is a 'custom form' type - when making payments or adding cards, third party payment form is served instead of the internal one
     * @return {boolean} boolean
     */
    public get isProviderFormType(): boolean {
        const providers = [OLO.Enums.PAYMENT_PROVIDER.ADYEN, OLO.Enums.PAYMENT_PROVIDER.STRIPE];

        return providers.includes(this._config.payments.baseProvider);
    }

    /**
     * Assert if google pay is configured
     * @return {boolean} boolean
     */
    public get isGooglePayEnabled(): boolean {
        return this._config.payments.googlePay.enabled === true && this.googlePayPaymentProviderService.isConfigured;
    }

    /**
     * Assert if apple pay is configured and environment allows Apple payments
     * @return {boolean} boolean
     */
    public get isApplePayEnabled(): boolean {
        return this.applePayPaymentProviderService.canUseApplePayApi;
    }

    public encodeCVV(cvv: string): string {
        if (this._config.payments.baseProvider === OLO.Enums.PAYMENT_PROVIDER.ADYEN) {
            return cvv;
        }

        const encodedCvv = this._cryptoService.encrypt(cvv, false, 'btoa');

        return encodedCvv;
    }

    public requestAdyenPreconfigurationSetup(locationNo: number): Observable<State.IAdyenSettingsResponse> {
        return this.store
            .pipe(
                select(selectors.getLoyaltyAppSettings),
                filter(appSettings => appSettings.data !== null),
                take(1),
                switchMap(appSettings => this.adyenPaymentProviderService.requestConfig(locationNo, appSettings.data?.AppSettings?.DefaultAdyenSettings)),
                catchError(ex => {
                    console.error('Unable to request config data for Adyen PP.', ex);

                    return throwError(ex);
                })
            );
    }

    public requestStripePreconfigurationSetup(locationNo: number): Observable<State.IStripeSettingsResponse> {
        return this.stripePaymentProviderService.requestConfig(locationNo);
    }

    public requestCardTokenForDefaultPaymentProvider(
        cardData: OLO.CreditCards.ICreditCardDetails,
        locationNo: number = null
    ): Observable<APICommon.IPaymentProviderDefaultConfigResponse> {
        return this.store
            .pipe(
                select(selectors.getLoyaltyAppSettings),
                filter(appSettings => appSettings.data !== null),
                take(1),
                flatMap(appSettings => {
                    switch (true) {
                        case this._config.payments.baseProvider === OLO.Enums.PAYMENT_PROVIDER.CONVERGE
                        && appSettings.data.AppSettings.DefaultConvergeSettings !== null
                        && this.convergePaymentProviderService !== null:

                            return this.convergePaymentProviderService.requestCardToken(cardData, locationNo, appSettings.data.AppSettings.DefaultConvergeSettings)
                                .pipe(
                                    map(convergeResponse => ({
                                        token: convergeResponse.ssl_token
                                    }))
                                );

                        case this._config.payments.baseProvider === OLO.Enums.PAYMENT_PROVIDER.CARD_CONNECT
                        && appSettings.data.AppSettings.DefaultCardConnectSettings !== null
                        && this.cardConnectPaymentProviderService !== null:
                            return this.cardConnectPaymentProviderService
                                .requestCardToken2$(cardData, locationNo)
                                .pipe(
                                    map(cardConnectResponse => ({
                                        token: cardConnectResponse.token,
                                    }))
                                );

                        case this._config.payments.baseProvider === OLO.Enums.PAYMENT_PROVIDER.PAYMENT_EXPRESS
                        && appSettings.data.AppSettings.DefaultPaymentExpressSettings !== null
                        && this.paymentExpressPaymentProviderService !== null:
                            return this.paymentExpressPaymentProviderService
                                .requestCardToken(locationNo, appSettings.data.AppSettings.DefaultPaymentExpressSettings)
                                .pipe(
                                    map(paymentExpressResponse => ({
                                        token: paymentExpressResponse.SessionToken,
                                        directPostUrl: paymentExpressResponse.Url
                                    }))
                                );

                        case this._config.payments.baseProvider === OLO.Enums.PAYMENT_PROVIDER.FAT_ZEBRA
                        && appSettings.data.AppSettings.DefaultFatZebraSettings !== null
                        && this.fatZebraPaymentProviderService !== null:
                            return this.fatZebraPaymentProviderService
                                .requestCardToken(locationNo, appSettings.data.AppSettings.DefaultFatZebraSettings)
                                .pipe(
                                    map(fatZebraResponse => ({
                                        token: fatZebraResponse.Verification,
                                        directPostUrl: fatZebraResponse.DirectPostUrl,
                                        returnUrlAfterRedirect: fatZebraResponse.ReturnPath
                                    }))
                                );

                        default:
                            return throwError('Default payment provider not configured');

                    }
                }),
                catchError(ex => {
                    console.error('Unable to get card token', ex);

                    return throwError(ex);
                })
            );
    }

    public payWithAccountCharge(orderId: number, requestModel: IExecuteOnlineOrderAccountChargeModel): Observable<IExecuteAccountChargeResponse> {
        const mapedRequestModel: APIv3.ExecuteOnlineOrderAccountChargeModel = PaymentsMapper.mapPayWithAccountChargePOSTRequest(requestModel);

        return this.httpClient
            .post<APIv3.ExecuteAccountChargeResponse>(
            `${Utils.HTTP.switchApi(this._config.api.base)}/OnlineOrders/${orderId}/accountCharge`, mapedRequestModel)
            .pipe(
                map((response: APIv3.ExecuteAccountChargeResponse) => PaymentsMapper.mapPayWithAccountChargePOSTResponse(response))
            );
    }

    public pay(orderId: number, requestModel: IExecutePaymentModel): Observable<IExecutePaymentResponse> {
        const mapedRequestModel: APIv3.ExecutePaymentModel = PaymentsMapper.mapPayPOSTRequest(requestModel);

        return this.httpClient
            .post<APIv3.ExecutePaymentResponse>(`${Utils.HTTP.switchApi(this._config.api.base)}/OnlineOrders/${orderId}/pay`, mapedRequestModel)
            .pipe(
                map((response: APIv3.ExecutePaymentResponse) => PaymentsMapper.mapPayPOSTResponse(response))
            );
    }

    public getPaymentStatus(transactionId: string): Observable<IGetTransactionResponse> {
        return this.httpClient
            .get<APIv3.GetTransactionResponse>(`${Utils.HTTP.switchApi(this._config.api.base)}/Payments/${transactionId}`)
            .pipe(
                map((response: APIv3.GetTransactionResponse) => PaymentsMapper.maPaymentStatusGETResponse(response))
            );
    }

    public resetPaymentFlow(): void {
        this.store.dispatch(actions.PaymentReset());
    }

    public cleanUp(): void {
        /* When exiting checkout page, make sure online order, payment and credit card data is reset */
        this.store.dispatch(actions.OnlineOrderStateReset());
        this.store.dispatch(actions.CreditCardTokenDataReset());
        this.store.dispatch(actions.PaymentReset());
    }

    public get defaultVendorPaymentProvider(): Nullable<OLO.Enums.PAYMENT_SERVICE> {
        if(!this.isSpecialPaymentMethodAvailable) {
            return null;
        }

        if(this.isApplePayAvailable) return OLO.Enums.PAYMENT_SERVICE.APPLE_PAY;

        if(this.isGooglePayAvailable) return OLO.Enums.PAYMENT_SERVICE.GOOGLE_PAY;

        if(this.isPayInStoreAvailable) return OLO.Enums.PAYMENT_SERVICE.PAY_IN_STORE;

        throw new Error('Invalid default vendor payment condition');
    }

    public get isVendorPaymentProviderAvailable(): boolean {
        return this.isApplePayAvailable || this.isGooglePayAvailable;
    }

    public get isApplePayAvailable(): boolean {
        return this.applePayPaymentProviderService.canUseApplePayApi && this._config.payments?.applePay?.enabled === true;
    }

    public get isGooglePayAvailable(): boolean {
        return this.googlePayPaymentProviderService.isConfigured && this._config.payments?.googlePay?.enabled === true;
    }

    public get isPayInStoreAvailable(): boolean {
        return this._config.payments.payInStore === true;
    }

    public get isSpecialPaymentMethodAvailable(): boolean {
        return this.isVendorPaymentProviderAvailable || this.isPayInStoreAvailable;
    }
}
