import { createReducer, on, Action } from '@ngrx/store';
import { IMembers } from './members.interface';
import * as actions from './members.actions';

const initialState: IMembers = {
    isDownloading: false,
    hasFailed: false,
    hasSucceeded: false,

    guestValidationDetails: {
        login: null,
        loginType: null,
        isValidating: false,
        hasSucceeded: false,
        hasFailed: false,
        validatedDate: null,
        data: null,
    },

    deleteProfile: {
        isDeleting: false,
        hasSucceeded: false,
        hasFailed: false,
        deletedDate: null,
    },

    accountBalance: {
        isDownloading: false,
        hasSucceeded: false,
        hasFailed: false,
        downloadedDate: null,
        data: null,
    },

    uniqueCode: {
        memberCode: null,
        isDownloading: false,
        hasSucceeded: false,
        hasFailed: false,
        downloadedDate: null,
        data: null,
    },

    freeProducts: {
        isDownloading: false,
        hasSucceeded: false,
        hasFailed: false,
        downloadedDate: null,
        data: null,
    },
    loyaltyProducts: {
        isDownloading: false,
        hasSucceeded: false,
        hasFailed: false,
        downloadedDate: null,
        data: null,
    },

    forgottenPasswordReset: {
        isResetting: false,
        hasSucceeded: false,
        hasFailed: false,
        token: {
            isValidating: false,
            hasSucceeded: false,
            hasFailed: false,
            isValid: null,
        },
    },

    emailConfirm: {
        isConfirming: false,
        hasSucceeded: false,
        hasFailed: false,
        token: {
            isValidating: false,
            hasSucceeded: false,
            hasFailed: false,
            isValid: null,
        },
    },

    update: {
        isUpdating: false,
        updateHasSucceeded: false,
        updateHasFailed: false,
        data: null
    },

    setPassword: {
        isSetting: false,
        hasSucceeded: false,
        hasFailed: false,
        error: null,
    },

    changePassword: {
        isChanging: false,
        hasSucceeded: false,
        hasFailed: false,
        error: null,
    },

    forgotPassword: {
        isSending: false,
        hasSucceeded: false,
        hasFailed: false,
        email: null,
    },

    validateLogin: {
        isValidating: false,
        hasSucceeded: false,
        hasFailed: false,
    },
    validatePhone: {
        isValidating: false,
        hasSucceeded: false,
        hasFailed: false,
    },
    validateEmail: {
        isValidating: false,
        hasSucceeded: false,
        hasFailed: false,
    },
    signIn: {
        isSigningIn: false,
        hasSucceeded: false,
        hasFailed: false,
    },

    signUp: {
        isSigningUp: false,
        hasSucceeded: false,
        hasFailed: false,
    },

    signUpPartialMember: {
        isSigningUp: false,
        hasSucceeded: false,
        hasFailed: false,
    },

    jwt: {
        isDownloading: false,
        hasSucceeded: false,
        hasFailed: false,
    },

    verifyPhone: {
        isVerifying: false,
        hasSucceeded: false,
        hasFailed: false,
    },
    verifyEmail: {
        isVerifying: false,
        hasSucceeded: false,
        hasFailed: false,
    },
    verifyLinkRewardsAccount: {
        memberEmail: null,
        memberCardNo: null,
        isVerifying: false,
        hasSucceeded: false,
        hasFailed: false,
        downloadedDate: null,
        data: null,
    },

    tokenForPhone: {
        isSending: false,
        hasSucceeded: false,
        hasFailed: false,
    },

    tokenForEmail: {
        isSending: false,
        hasSucceeded: false,
        hasFailed: false,
    },

    linkRewardsAccount: {
        isLinking: false,
        hasSucceeded: false,
        hasFailed: false,
        linkedDate: null,
        password: null,
    },

    points: {
        isDownloading: false,
        hasSucceeded: false,
        hasFailed: false,
        data: null
    },

    isAuthorized: false,
    accountId: null,
    accountLogin: null,
    accountPassword: null,
    authorizationType: null,
    authorizationStep: OLO.Enums.AUTH_STEP.LOGIN,

    sessionKey: null,

    isGuestModeEnabled: false,
    guestData: null,

    addData: null,
    data: null,
};

export const memberReducerFn = createReducer(
    initialState,
    on(
        actions.MemberGuestDataRequest,
        (state, action) => ({
            ...state,
            guestValidationDetails: {
                ...state.guestValidationDetails,
                login: action.login,
                loginType: action.loginType,
                isValidating: true,
                hasSucceeded: false,
                hasFailed: false,
            }
        })
    ),
    on(
        actions.MemberGuestDataSuccessRequest,
        (state, action) => ({
            ...state,
            guestValidationDetails: {
                ...state.guestValidationDetails,
                login: action.login,
                loginType: action.loginType,
                isValidating: false,
                hasSucceeded: true,
                hasFailed: false,
                validatedDate: Date.now(),
                data: action.payload || null
            }
        })
    ),
    on(
        actions.MemberGuestDataRequest,
        (state, action) => ({
            ...state,
            guestValidationDetails: {
                ...state.guestValidationDetails,
                login: action.login,
                loginType: action.loginType,
                isValidating: false,
                hasSucceeded: true,
                hasFailed: false,
            }
        })
    ),
    on(
        actions.MemberDeleteProfileRequest,
        (state) => ({
            ...state,
            deleteProfile: {
                ...state.deleteProfile,
                isDeleting: true,
                hasSucceeded: false,
                hasFailed: false,
            }
        })
    ),
    on(
        actions.MemberDeleteProfileSuccessRequest,
        (state) => ({
            ...state,
            deleteProfile: {
                ...state.deleteProfile,
                isDeleting: false,
                hasSucceeded: true,
                hasFailed: false,
                deletedDate: new Date().getTime(),
            }
        })
    ),
    on(
        actions.MemberDeleteProfileErrorRequest,
        (state) => ({
            ...state,
            deleteProfile: {
                ...state.deleteProfile,
                isDeleting: false,
                hasSucceeded: false,
                hasFailed: true,
            }
        })
    ),
    on(
        actions.MemberLinkRewardsAccountVerifyReset,
        (state, action) => ({
            ...state,
            verifyLinkRewardsAccount: {
                memberEmail: null,
                memberCardNo: null,
                isVerifying: false,
                hasSucceeded: false,
                hasFailed: false,
                downloadedDate: null,
                data: null,
            },
        })
    ),
    on(
        actions.MemberLinkRewardsAccountVerifyRequest,
        (state, action) => ({
            ...state,
            verifyLinkRewardsAccount: {
                ...state.verifyLinkRewardsAccount,
                memberEmail: action.params.email || null,
                memberCardNo: action.params.memberCardNo || null,
                isVerifying: true,
                hasSucceeded: false,
                hasFailed: false,
            },
        })
    ),
    on(
        actions.MemberLinkRewardsAccountVerifySuccessRequest,
        (state, action) => ({
            ...state,
            verifyLinkRewardsAccount: {
                ...state.verifyLinkRewardsAccount,
                isVerifying: false,
                hasSucceeded: true,
                hasFailed: false,
                downloadedDate: new Date().getTime(),
                data: action.payload,
            },
        })
    ),
    on(
        actions.MemberLinkRewardsAccountVerifyErrorRequest,
        (state, action) => ({
            ...state,
            verifyLinkRewardsAccount: {
                ...state.verifyLinkRewardsAccount,
                isVerifying: false,
                hasSucceeded: false,
                hasFailed: true,
            },
        })
    ),

    on(
        actions.MemberLinkRewardsAccountRequest,
        (state, action) => ({
            ...state,
            linkRewardsAccount: {
                ...state.linkRewardsAccount,
                isLinking: true,
                hasSucceeded: false,
                hasFailed: false,
                password: action.password,
            }
        })
    ),

    on(
        actions.MemberLinkRewardsAccountSuccessRequest,
        (state, action) => ({
            ...state,
            linkRewardsAccount: {
                ...state.linkRewardsAccount,
                isLinking: false,
                hasSucceeded: true,
                hasFailed: false,
                linkedDate: new Date().getTime(),
                password: null,
            },
            data: action.payload,
        })
    ),

    on(
        actions.MemberLinkRewardsAccountErrorRequest,
        (state, action) => ({
            ...state,
            linkRewardsAccount: {
                ...state.linkRewardsAccount,
                isLinking: false,
                hasSucceeded: false,
                hasFailed: true,
            }
        })
    ),

    on(
        actions.MemberAccountBalanceRequest,
        (state) => ({
            ...state,
            accountBalance: {
                ...state.accountBalance,
                isDownloading: true,
                hasSucceeded: false,
                hasFailed: false,
            }
        })
    ),
    on(
        actions.MemberAccountBalanceSuccessRequest,
        (state, action) => ({
            ...state,
            accountBalance: {
                ...state.accountBalance,
                isDownloading: false,
                hasSucceeded: true,
                hasFailed: false,
                downloadedDate: new Date().getTime(),
                data: action.payload,
            }
        })
    ),
    on(
        actions.MemberAccountBalanceErrorRequest,
        (state, action) => ({
            ...state,
            accountBalance: {
                ...state.accountBalance,
                isDownloading: false,
                hasSucceeded: false,
                hasFailed: true,
            }
        })
    ),

    on(
        actions.MemberUniqueCodeCheckReset,
        (state, action) => ({
            ...state,
            uniqueCode: {
                memberCode: null,
                isDownloading: false,
                hasSucceeded: false,
                hasFailed: false,
                downloadedDate: null,
                data: null,
            },
        })
    ),
    on(
        actions.MemberUniqueCodeCheckRequest,
        (state, action) => ({
            ...state,
            uniqueCode: {
                memberCode: action.memberCode,
                isDownloading: true,
                hasSucceeded: false,
                hasFailed: false,
                downloadedDate: null,
                data: null,
            },
        })
    ),
    on(
        actions.MemberUniqueCodeCheckSuccessRequest,
        (state, action) => ({
            ...state,
            uniqueCode: {
                memberCode: action.memberCode,
                isDownloading: false,
                hasSucceeded: true,
                hasFailed: false,
                downloadedDate: new Date().getTime(),
                data: { ...action.payload },
            },
        })
    ),
    on(
        actions.MemberUniqueCodeCheckErrorRequest,
        (state, action) => ({
            ...state,
            uniqueCode: {
                memberCode: action.memberCode,
                isDownloading: false,
                hasSucceeded: false,
                hasFailed: true,
                downloadedDate: null,
                data: null,
            },
        })
    ),
    on(
        actions.MemberFreeProductsRequest,
        state => ({
            ...state,
            freeProducts: {
                ...state.freeProducts,
                isDownloading: true,
                hasSucceeded: false,
                hasFailed: false,
            }
        })
    ),
    on(
        actions.MemberFreeProductsSuccessRequest,
        (state, action) => ({
            ...state,
            freeProducts: {
                ...state.freeProducts,
                isDownloading: false,
                hasSucceeded: true,
                hasFailed: false,
                downloadedDate: new Date().getTime(),
                data: [
                    ...action.payload
                ]
            }
        })
    ),
    on(
        actions.MemberFreeProductsErrorRequest,
        state => ({
            ...state,
            freeProducts: {
                ...state.freeProducts,
                isDownloading: false,
                hasSucceeded: false,
                hasFailed: true,
            }
        })
    ),

    on(
        actions.MemberLoyaltyProductsRequest,
        state => ({
            ...state,
            loyaltyProducts: {
                ...state.loyaltyProducts,
                isDownloading: true,
                hasSucceeded: false,
                hasFailed: false,
            }
        })
    ),
    on(
        actions.MemberLoyaltyProductsSuccessRequest,
        (state, action) => ({
            ...state,
            loyaltyProducts: {
                ...state.loyaltyProducts,
                isDownloading: false,
                hasSucceeded: true,
                hasFailed: false,
                downloadedDate: new Date().getTime(),
                data: [
                    ...action.payload
                ]
            }
        })
    ),
    on(
        actions.MemberLoyaltyProductsErrorRequest,
        state => ({
            ...state,
            loyaltyProducts: {
                ...state.loyaltyProducts,
                isDownloading: false,
                hasSucceeded: false,
                hasFailed: true,
            }
        })
    ),

    on(
        actions.MemberUnsetUpdateAndValidation,
        state => ({
            ...state,
            update: {
                ...initialState.update,
            },
            deleteProfile: {
                ...initialState.deleteProfile
            },
            tokenForEmail: {
                ...initialState.tokenForEmail,
            },
            tokenForPhone: {
                ...initialState.tokenForPhone,
            },
            verifyEmail: {
                ...initialState.verifyEmail,
            },
            verifyPhone: {
                ...initialState.verifyPhone,
            },
            verifyLinkRewardsAccount: {
                ...initialState.verifyLinkRewardsAccount,
            },
            linkRewardsAccount: {
                ...initialState.linkRewardsAccount
            },
            emailConfirm: {
                ...initialState.emailConfirm,
                token: {
                    ...initialState.emailConfirm.token
                }
            },
            forgottenPasswordReset: {
                ...initialState.forgottenPasswordReset,
                token: {
                    ...initialState.forgottenPasswordReset.token,
                }
            },
            forgotPassword: {
                ...initialState.forgotPassword,
            },
            validateLogin: {
                ...initialState.validateLogin,
            },
            validateEmail: {
                ...initialState.validateEmail,
            },
            validatePhone: {
                ...initialState.validatePhone,
            },
            uniqueCode: {
                ...initialState.uniqueCode,
            },
        })
    ),
    on(
        actions.MemberUnsetAccountCredentials,
        (state, action) => ({
            ...state,
            accountLogin: null,
            accountPassword: null,
        })
    ),
    on(
        actions.MemberUnsetAddData,
        (state, action) => ({
            ...state,
            addData: null,
        })
    ),
    on(
        actions.MemberConfirmEmailReset,
        (state, action) => ({
            ...state,
            emailConfirm: {
                ...state.emailConfirm,
                hasSucceeded: false,
                hasFailed: false,
                isConfirming: false,
                token: {
                    ...state.emailConfirm.token,
                    hasFailed: false,
                    hasSucceeded: false,
                    isValidating: false,
                    isValid: null,
                }
            }
        })
    ),
    on(
        actions.MemberConfirmEmailRequest,
        (state, action) => ({
            ...state,
            emailConfirm: {
                ...state.emailConfirm,
                hasSucceeded: false,
                hasFailed: false,
                isConfirming: true,
            }
        })
    ),
    on(
        actions.MemberConfirmEmailSuccessRequest,
        (state, action) => ({
            ...state,
            emailConfirm: {
                ...state.emailConfirm,
                hasSucceeded: true,
                hasFailed: false,
                isConfirming: false,
            }
        })
    ),
    on(
        actions.MemberConfirmEmailErrorRequest,
        (state, action) => ({
            ...state,
            emailConfirm: {
                ...state.emailConfirm,
                hasSucceeded: false,
                hasFailed: true,
                isConfirming: false,
            }
        })
    ),
    on(
        actions.MemberConfirmEmailTokenRequest,
        (state, action) => ({
            ...state,
            emailConfirm: {
                ...state.emailConfirm,
                token: {
                    ...state.emailConfirm.token,
                    hasFailed: false,
                    hasSucceeded: false,
                    isValidating: true,
                }
            }
        })
    ),
    on(
        actions.MemberConfirmEmailTokenSuccessRequest,
        (state, action) => ({
            ...state,
            emailConfirm: {
                ...state.emailConfirm,
                token: {
                    ...state.emailConfirm.token,
                    hasFailed: false,
                    hasSucceeded: true,
                    isValidating: false,
                    isValid: action.payload
                }
            }
        })
    ),
    on(
        actions.MemberConfirmEmailTokenErrorRequest,
        (state, action) => ({
            ...state,
            emailConfirm: {
                ...state.emailConfirm,
                token: {
                    ...state.emailConfirm.token,
                    hasFailed: true,
                    hasSucceeded: false,
                    isValidating: false,
                }
            }
        })
    ),
    on(
        actions.MemberForgottenPasswordResetRequest,
        (state, action) => ({
            ...state,
            forgottenPasswordReset: {
                ...state.forgottenPasswordReset,
                hasFailed: false,
                hasSucceeded: false,
                isResetting: true,
            }
        })
    ),
    on(
        actions.MemberForgottenPasswordResetSuccessRequest,
        (state, action) => ({
            ...state,
            forgottenPasswordReset: {
                ...state.forgottenPasswordReset,
                hasFailed: false,
                hasSucceeded: true,
                isResetting: false,
            }
        })
    ),
    on(
        actions.MemberForgottenPasswordResetErrorRequest,
        (state, action) => ({
            ...state,
            forgottenPasswordReset: {
                ...state.forgottenPasswordReset,
                hasFailed: true,
                hasSucceeded: false,
                isResetting: false,
            }
        })
    ),
    on(
        actions.MemberValidatePasswordResetTokenRequest,
        (state, action) => ({
            ...state,
            forgottenPasswordReset: {
                ...state.forgottenPasswordReset,
                token: {
                    ...state.forgottenPasswordReset.token,
                    isValidating: true,
                    hasFailed: false,
                    hasSucceeded: false,
                    isValid: null,
                }
            }
        })
    ),
    on(
        actions.MemberValidatePasswordResetTokenSuccessRequest,
        (state, action) => ({
            ...state,
            forgottenPasswordReset: {
                ...state.forgottenPasswordReset,
                token: {
                    ...state.forgottenPasswordReset.token,
                    isValidating: false,
                    hasFailed: false,
                    hasSucceeded: true,
                    isValid: action.payload,
                }
            }
        })
    ),
    on(
        actions.MemberValidatePasswordResetTokenErrorRequest,
        (state, action) => ({
            ...state,
            forgottenPasswordReset: {
                ...state.forgottenPasswordReset,
                token: {
                    ...state.forgottenPasswordReset.token,
                    isValidating: false,
                    hasFailed: true,
                    hasSucceeded: false,
                }
            }
        })
    ),
    on(
        actions.MemberValidatePasswordResetTokenReset,
        (state, action) => ({
            ...state,
            forgottenPasswordReset: {
                ...state.forgottenPasswordReset,
                token: {
                    ...state.forgottenPasswordReset.token,
                    isValidating: false,
                    hasFailed: false,
                    hasSucceeded: false,
                    isValid: null,
                }
            }
        })
    ),
    on(
        actions.MemberAuthorizationSetFlag,
        (state, action) => ({
            ...state,
            isAuthorized: action.flag
        })
    ),
    on(
        actions.MemberAuthorizationSetStep,
        (state, action) => ({
            ...state,
            authorizationStep: action.step
        })
    ),
    on(
        actions.MemberAuthorizationInit,
        (state, action) => ({
            ...JSON.parse(JSON.stringify(initialState)),
            accountLogin: action.accountLogin,
            authorizationType: action.authorizationType,
            authorizationStep: state.authorizationStep,
        })
    ),
    on(
        actions.MemberValidateLoginRequest,
        (state, action) => ({
            ...state,
            validateLogin: {
                ...state.validateLogin,
                isValidating: true,
                hasSucceeded: false,
                hasFailed: false,
            }
        })
    ),
    on(
        actions.MemberValidateLoginErrorRequest,
        (state, action) => ({
            ...state,
            validateLogin: {
                ...state.validateLogin,
                isValidating: false,
                hasSucceeded: false,
                hasFailed: true,
            }
        })
    ),
    on(
        actions.MemberValidateLoginSuccessRequest,
        (state, action) => ({
            ...state,
            validateLogin: {
                ...state.validateLogin,
                isValidating: false,
                hasSucceeded: true,
                hasFailed: false,
            },
            accountId: action.payload ? action.payload.MemberId : null,
            accountIsValid: action.payload ? action.payload.IsOnlineRegistered : null,
            data: action.payload ? { ...action.payload } : null,
        })
    ),
    on(
        actions.MemberSignInDataRequest,
        (state, action) => ({
            ...state,
            signIn: {
                ...state.signIn,
                isSigningIn: true,
                hasSucceeded: false,
                hasFailed: false,
            },
            accountPassword: action.password
        })
    ),
    on(
        actions.MemberSignInDataResponseError,
        (state, action) => ({
            ...state,
            signIn: {
                ...state.signIn,
                isSigningIn: false,
                hasSucceeded: false,
                hasFailed: true,
            },
        })
    ),
    on(
        actions.MemberSignInDataResponseSuccess,
        (state, action) => ({
            ...state,
            signIn: {
                ...state.signIn,
                isSigningIn: false,
                hasSucceeded: true,
                hasFailed: false,
            },
            sessionKey: action.data.sessionKey,
        })
    ),
    on(
        actions.MemberJwtDataRequest,
        (state, action) => ({
            ...state,
            jwt: {
                ...state.jwt,
                isDownloading: true,
                hasSucceeded: false,
                hasFailed: false,
            },
        })
    ),
    on(
        actions.MemberJwtDataResponseError,
        (state, action) => ({
            ...state,
            jwt: {
                ...state.jwt,
                isDownloading: false,
                hasSucceeded: false,
                hasFailed: true,
            },
        })
    ),
    on(
        actions.MemberJwtDataResponseSuccess,
        (state, action) => ({
            ...state,
            jwt: {
                ...state.jwt,
                isDownloading: false,
                hasSucceeded: true,
                hasFailed: false,
            },
            accountPassword: null,
        })
    ),
    on(
        actions.MemberJwtSetData,
        (state, action) => ({
            ...state,
            jwt: {
                ...state.jwt,
                isDownloading: false,
                hasSucceeded: true,
                hasFailed: false,
            },
            accountPassword: null,
        })
    ),
    on(
        actions.MemberValidatePhoneRequest,
        (state, action) => ({
            ...state,
            validatePhone: {
                ...state.validatePhone,
                isValidating: true,
                hasSucceeded: false,
                hasFailed: false,
            },
        })
    ),
    on(
        actions.MemberValidatePhoneDataResponseError,
        (state, action) => ({
            ...state,
            validatePhone: {
                ...state.validatePhone,
                isValidating: false,
                hasSucceeded: false,
                hasFailed: true,
            },
        })
    ),
    on(
        actions.MemberValidatePhoneSuccessRequest,
        (state, action) => ({
            ...state,
            validatePhone: {
                ...state.validatePhone,
                isValidating: false,
                hasSucceeded: true,
                hasFailed: false,
            },
            data: {
                ...state.data,
                MobilePhone: action.phone,
                MemberId: action.memberId ? action.memberId : state.data?.MemberId
            }
        })
    ),
    on(
        actions.MemberValidateEmailDataRequest,
        (state, action) => ({
            ...state,
            validateEmail: {
                ...state.validateEmail,
                isValidating: true,
                hasSucceeded: false,
                hasFailed: false,
            },
        })
    ),
    on(
        actions.MemberValidateEmailDataResponseError,
        (state, action) => ({
            ...state,
            validateEmail: {
                ...state.validateEmail,
                isValidating: false,
                hasSucceeded: false,
                hasFailed: true,
            },
        })
    ),
    on(
        actions.MemberValidateEmailDataResponseSuccess,
        (state, action) => ({
            ...state,
            validateEmail: {
                ...state.validateEmail,
                isValidating: false,
                hasSucceeded: true,
                hasFailed: false,
            },
            data: {
                ...state.data,
                Email: action.email,
                MemberId: action.memberId ? action.memberId : state.data?.MemberId
            }
        })
    ),
    on(
        actions.MemberSessionSuccessRequest,
        (state, action) => ({
            ...state,
            sessionKey: action.sessionKey,
            accountId: action.accountId,
            authorizationType: action.authorizationType,
        })
    ),
    on(
        actions.MemberSessionErrorRequest,
        (state, action) => ({
            ...state,
            data: null,
            isGuestModeEnabled: true,
            isAuthorized: false,
            sessionKey: action.sessionKey,
            accountId: action.accountId,
            authorizationType: action.authorizationType,
        })
    ),
    on(
        actions.MemberGuestModeSet,
        (state, action) => ({
            ...state,
            isGuestModeEnabled: action.flag
        })
    ),
    on(
        actions.MemberGuestDataSet,
        (state, action) => ({
            ...state,
            guestData: action.guestData
        })
    ),
    on(
        actions.MemberGuestDataReset,
        (state, action) => ({
            ...state,
            guestData: null
        })
    ),
    on(
        actions.MemberDataReset,
        (state, action) => ({
            ...state,
            data: null
        })
    ),
    on(
        actions.MemberSignUpProcessInit,
        (state, action) => ({
            ...state,
            addData: action.memberData
        })
    ),
    on(
        actions.MemberPartialSignUpRequest,
        (state, action) => ({
            ...state,
            signUpPartialMember: {
                ...state.signUpPartialMember,
                isSigningUp: true,
                hasSucceeded: false,
                hasFailed: false,
            }
        })
    ),
    on(
        actions.MemberPartialSignUpSuccessRequest,
        (state, action) => ({
            ...state,
            signUpPartialMember: {
                ...state.signUpPartialMember,
                isSigningUp: false,
                hasSucceeded: true,
                hasFailed: false,
            },
            data: {
                ...action.payload
            },
        })
    ),

    on(
        actions.MemberPartialSignUpErrorRequest,
        (state, action) => ({
            ...state,
            signUpPartialMember: {
                ...state.signUpPartialMember,
                isSigningUp: false,
                hasSucceeded: false,
                hasFailed: true,
            }
        })
    ),
    on(
        actions.MemberPartialSignUpResetState,
        (state, action) => ({
            ...state,
            signUpPartialMember: {
                ...state.signUpPartialMember,
                isSigningUp: false,
                hasSucceeded: false,
                hasFailed: false,
            }
        })
    ),
    on(
        actions.MemberSignUpRequest,
        (state, action) => ({
            ...state,
            signUp: {
                ...state.signUp,
                isSigningUp: true,
                hasSucceeded: false,
                hasFailed: false,
            }
        })
    ),
    on(
        actions.MemberSignUpSuccessRequest,
        (state, action) => ({
            ...state,
            signUp: {
                ...state.signUp,
                isSigningUp: false,
                hasSucceeded: true,
                hasFailed: false,
            },
            data: {
                ...state.data,
                ...action.memberData
            },
            accountId: action.memberData.MemberId,

        })
    ),
    on(
        actions.MemberSignUpErrorRequest,
        (state, action) => ({
            ...state,
            signUp: {
                ...state.signUp,
                isSigningUp: false,
                hasSucceeded: false,
                hasFailed: true,
            }
        })
    ),
    on(
        actions.MemberDataRequest,
        (state, action) => ({
            ...state,
            isDownloading: true,
            hasFailed: false,
            hasSucceeded: false,
        })
    ),
    on(
        actions.MemberDataSuccessRequest,
        (state, action) => ({
            ...state,
            data: {
                ...state.data,
                ...action.payload
            },
            isDownloading: false,
            hasFailed: false,
            hasSucceeded: true,
        })
    ),
    on(
        actions.MemberDataErrorRequest,
        (state, action) => ({
            ...state,
            isDownloading: false,
            hasFailed: true,
            hasSucceeded: false,
        })
    ),
    on(
        actions.MemberProfileUpdateInit,
        (state, action) => ({
            ...state,
            update: {
                ...state.update,
                isUpdating: false,
                updateHasSucceeded: false,
                updateHasFailed: false,
                data: {
                    ...action.userModel
                }
            }
        })
    ),
    on(
        actions.MemberProfileUpdateRequest,
        (state, action) => ({
            ...state,
            update: {
                ...state.update,
                isUpdating: true,
                updateHasSucceeded: false,
                updateHasFailed: false,
                data: {
                    ...action.userModel
                }
            }
        })
    ),
    on(
        actions.MemberProfileUpdateSuccessRequest,
        (state, action) => ({
            ...state,
            update: {
                ...state.update,
                isUpdating: false,
                updateHasSucceeded: true,
                updateHasFailed: false,
                data: null,
            },
            data: action.payload
        })
    ),
    on(
        actions.MemberProfileUpdateErrorRequest,
        (state, action) => ({
            ...state,
            update: {
                ...state.update,
                isUpdating: false,
                updateHasSucceeded: false,
                updateHasFailed: true,
                data: null,
            }
        })
    ),
    on(
        actions.MemberUpdateRequest,
        (state, action) => ({
            ...state,
            update: {
                ...state.update,
                isUpdating: true,
                updateHasSucceeded: false,
                updateHasFailed: false,
            }
        })
    ),
    on(
        actions.MemberUpdateSuccessRequest,
        (state, action) => ({
            ...state,
            update: {
                ...state.update,
                isUpdating: false,
                updateHasSucceeded: true,
                updateHasFailed: false,
            },
            data: action.payload
        })
    ),
    on(
        actions.MemberUpdateErrorRequest,
        (state, action) => ({
            ...state,
            update: {
                ...state.update,
                isUpdating: false,
                updateHasSucceeded: false,
                updateHasFailed: true,
            }
        })
    ),
    on(
        actions.MemberRestoreUpdateFlags,
        (state, action) => ({
            ...state,
            update: {
                ...state.update,
                isUpdating: false,
                updateHasSucceeded: false,
                updateHasFailed: false,
            }
        })
    ),
    on(
        actions.MemberPasswordSetRequest,
        (state, action) => ({
            ...state,
            setPassword: {
                ...state.setPassword,
                isSetting: true,
                hasSucceeded: false,
                hasFailed: false,
                error: null,
            }
        })
    ),
    on(
        actions.MemberPasswordSetSuccessRequest,
        (state, action) => ({
            ...state,
            setPassword: {
                ...state.setPassword,
                isSetting: false,
                hasSucceeded: true,
                hasFailed: false,
                error: null,
            }
        })
    ),
    on(
        actions.MemberPasswordSetErrorRequest,
        (state, action) => ({
            ...state,
            setPassword: {
                ...state.setPassword,
                isSetting: false,
                hasSucceeded: false,
                hasFailed: true,
                error: action.ex || null,
            }
        })
    ),
    on(
        actions.MemberPasswordSetResetState,
        (state, action) => ({
            ...state,
            setPassword: {
                ...state.setPassword,
                isSetting: false,
                hasSucceeded: false,
                hasFailed: false,
                error: null,
            }
        })
    ),
    on(
        actions.MemberPasswordChangeRequest,
        (state, action) => ({
            ...state,
            changePassword: {
                ...state.changePassword,
                isChanging: true,
                hasSucceeded: false,
                hasFailed: false,
                error: null,
            }
        })
    ),
    on(
        actions.MemberPasswordChangeSuccessRequest,
        (state, action) => ({
            ...state,
            changePassword: {
                ...state.changePassword,
                isChanging: false,
                hasSucceeded: true,
                hasFailed: false,
                error: null,
            }
        })
    ),
    on(
        actions.MemberPasswordChangeErrorRequest,
        (state, action) => ({
            ...state,
            changePassword: {
                ...state.changePassword,
                isChanging: false,
                hasSucceeded: false,
                hasFailed: true,
                error: action.ex || null,
            }
        })
    ),
    on(
        actions.MemberPasswordChangeRestoreFlags,
        (state, action) => ({
            ...state,
            changePassword: {
                ...state.changePassword,
                isChanging: false,
                hasSucceeded: false,
                hasFailed: false,
                error: null,
            }
        })
    ),
    on(
        actions.MemberForgotPasswordDataRequest,
        (state, action) => ({
            ...state,
            forgotPassword: {
                ...state.forgotPassword,
                isSending: true,
                hasSucceeded: false,
                hasFailed: false,
                email: action.email,
            },
        })
    ),
    on(
        actions.MemberForgotPasswordDataResponseError,
        (state, action) => ({
            ...state,
            forgotPassword: {
                ...state.forgotPassword,
                isSending: false,
                hasSucceeded: false,
                hasFailed: true,
            },
        })
    ),
    on(
        actions.MemberForgotPasswordDataResponseSuccess,
        (state, action) => ({
            ...state,
            forgotPassword: {
                ...state.forgotPassword,
                isSending: false,
                hasSucceeded: true,
                hasFailed: false,
            },
        })
    ),
    on(
        actions.MemberForgotPasswordRestoreFlags,
        (state, action) => ({
            ...state,
            forgotPassword: {
                ...state.forgotPassword,
                isSending: false,
                hasSucceeded: false,
                hasFailed: false,
                email: null,
            },
        })
    ),
    on(
        actions.MemberSendPhoneVerificationCodeDataRequest,
        (state, action) => ({
            ...state,
            tokenForPhone: {
                ...state.tokenForPhone,
                isSending: true,
                hasSucceeded: false,
                hasFailed: false,
            },
        })
    ),
    on(
        actions.MemberSendPhoneVerificationCodeDataResponseError,
        (state, action) => ({
            ...state,
            tokenForPhone: {
                ...state.tokenForPhone,
                isSending: false,
                hasSucceeded: false,
                hasFailed: true,
            },
        })
    ),
    on(
        actions.MemberSendPhoneVerificationCodeDataResponseSuccess,
        (state, action) => ({
            ...state,
            tokenForPhone: {
                ...state.tokenForPhone,
                isSending: false,
                hasSucceeded: true,
                hasFailed: false,
            },
        })
    ),
    on(
        actions.MemberVerifyPhoneDataRequest,
        (state, action) => ({
            ...state,
            verifyPhone: {
                ...state.verifyPhone,
                isVerifying: true,
                hasSucceeded: false,
                hasFailed: false,
            },
        })
    ),
    on(
        actions.MemberVerifyPhoneDataErrorRequest,
        (state, action) => ({
            ...state,
            verifyPhone: {
                ...state.verifyPhone,
                isVerifying: false,
                hasSucceeded: false,
                hasFailed: true,
            },
        })
    ),
    on(
        actions.MemberVerifyPhoneDataSuccessRequest,
        (state, action) => ({
            ...state,
            verifyPhone: {
                ...state.verifyPhone,
                isVerifying: false,
                hasSucceeded: true,
                hasFailed: false,
            },
        })
    ),
    on(
        actions.MemberVerifyEmaiResetFlags,
        (state, action) => ({
            ...state,
            verifyEmail: {
                ...state.verifyEmail,
                isVerifying: false,
                hasSucceeded: false,
                hasFailed: false,
            },
        })
    ),
    on(
        actions.MemberVerifyEmailRequest,
        (state, action) => ({
            ...state,
            verifyEmail: {
                ...state.verifyEmail,
                isVerifying: true,
                hasSucceeded: false,
                hasFailed: false,
            },
        })
    ),
    on(
        actions.MemberVerifyEmailErrorRequest,
        (state, action) => ({
            ...state,
            verifyEmail: {
                ...state.verifyEmail,
                isVerifying: false,
                hasSucceeded: false,
                hasFailed: true,
            },
        })
    ),
    on(
        actions.MemberVerifyEmailSuccessRequest,
        (state, action) => ({
            ...state,
            verifyEmail: {
                ...state.verifyEmail,
                isVerifying: false,
                hasSucceeded: true,
                hasFailed: false,
            },
        })
    ),
    on(
        actions.MemberVerifyPhoneRestoreFlags,
        (state, action) => ({
            ...state,
            verifyPhone: {
                ...state.verifyPhone,
                isVerifying: false,
                hasSucceeded: false,
                hasFailed: false,
            },
            tokenForPhone: {
                ...state.tokenForPhone,
                isSending: false,
                hasSucceeded: false,
                hasFailed: false,
            },
        })
    ),
    on(
        actions.MemberSendEmailVeryficationRequest,
        (state, action) => ({
            ...state,
            tokenForEmail: {
                ...state.tokenForEmail,
                isSending: true,
                hasSucceeded: false,
                hasFailed: false,
            },
        })
    ),
    on(
        actions.MemberSendEmailVeryficationSuccessRequest,
        (state, action) => ({
            ...state,
            tokenForEmail: {
                ...state.tokenForEmail,
                isSending: false,
                hasSucceeded: true,
                hasFailed: false,
            },
        })
    ),
    on(
        actions.MemberSendEmailVeryficationDataResponseError,
        (state, action) => ({
            ...state,
            tokenForEmail: {
                ...state.tokenForEmail,
                isSending: false,
                hasSucceeded: false,
                hasFailed: true,
            },
        })
    ),
    on(
        actions.MemberVerifyEmailRestoreFlags,
        (state, action) => ({
            ...state,
            tokenForEmail: {
                ...state.tokenForEmail,
                isSending: false,
                hasSucceeded: false,
                hasFailed: false,
            },
        })
    ),
    on(
        actions.MemberGetPointsRequest,
        (state, action) => ({
            ...state,
            points: {
                ...state.points,
                isDownloading: true,
                hasFailed: false,
                hasSucceeded: false,
            }
        })
    ),
    on(
        actions.MemberGetPointsRequestSuccessRequest,
        (state, action) => ({
            ...state,
            points: {
                ...state.points,
                isDownloading: false,
                hasFailed: false,
                hasSucceeded: true,
                data: action.points
            },
            data: {
                ...state.data,
                MemberPoints: action.points
            }
        })
    ),
    on(
        actions.MemberGetPointsRequestErrorRequest,
        (state, action) => ({
            ...state,
            points: {
                ...state.points,
                isDownloading: false,
                hasFailed: true,
                hasSucceeded: false,
            }
        })
    ),
    on(
        actions.MemberStateReset,
        (state, action) => (JSON.parse(JSON.stringify(initialState)))
    )

);

export function memberReducer(state: IMembers | undefined, action: Action) {
    return memberReducerFn(state, action);
}
