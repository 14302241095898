import { UntypedFormGroup } from '@angular/forms';

export class Forms {
    public static extractValues(form: UntypedFormGroup): { [key: string]: any; } {
        return Object.keys(form.controls).reduce((acc, controlName) => {
            const nextCtrl = form.get(controlName);

            /* TODO - support nested stuff */
            return ({
                ...acc,
                [controlName]: nextCtrl instanceof UntypedFormGroup ? Forms.extractValues(nextCtrl) : nextCtrl.value,
            });
        }
        , {});
    }

    public static labelToName(str: string): string {
        if (!str || typeof str !== 'string') return null;

        return str.toLowerCase().replace(/[^a-z]/g, '');
    }
}
