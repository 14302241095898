import * as State from '@shared/state';

import { Pricing } from './pricing.utils';
import { MenuFlows } from './menu-flows.utils';
import { IMenuFlowDetailsModel } from '@shared/state';

export class Items {
    public static requireDefaultActivation(menuFlowItem: State.IWizzardMenuFlow | APICommon.IOnlineMenuProductResponseModel): boolean {
        const menuFlowType = MenuFlows.extractType(menuFlowItem.Tags);

        return menuFlowType === 'standard-menu-flow' || menuFlowType === 'combined-pages-menu-flow';
    }

    public static generateWizzardMenuFlowItem(
        product: APICommon.IMenuFlowProduct,
        ingredient: APICommon.IProductLocationIngredientExtended = null,
        override: APICommon.IMenuFlowProduct = {},
    ): State.IWizzardMenuFlowItem {
        const addModifier = !!ingredient === true && ingredient.Ingredients && ingredient.Ingredients.length && ingredient.Ingredients[0];
        let modifierToAdd: APICommon.IIngredientModifierExtended;

        if (addModifier) {
            modifierToAdd = (ingredient.Ingredients !== null && ingredient.Ingredients[0] && ingredient.Ingredients[0].Modifiers && ingredient.Ingredients[0].Modifiers[0]) || null;

            if (!modifierToAdd) {
                console.warn('Setup issue with ingredient modifiers for product:', product, ' Ingredient is configured but modifier is not defined:', ingredient);
            }
        }

        return {
            ...product,

            IngredientsChanges: {
                IngredientsModified: modifierToAdd && !modifierToAdd._IsOptional ? [] : [],
                IngredientsAdded: modifierToAdd && modifierToAdd._IsOptional === true ? [{ ...modifierToAdd }] : [],
                IngredientsRemoved: [] /* NOT IN USE */,
                IngredientsSwapped: [] /* NOT IN USE */,
            },

            ...override,
        } as State.IWizzardMenuFlowItem;
    }

    public static createMenuFlowItemFromMenuFlowDetailsModel(
        menuFlowDetails: IMenuFlowDetailsModel,
        optionalParams: ICartMenuFlowParamsOptional<OLO.Ordering.IMenuFlowItemPageProduct> = {},
    ): OLO.Ordering.IMenuFlowItem {
        return {
            _Id: optionalParams._Id || null,
            IsUpsell: optionalParams.IsUpsell || false,
            LocationNo: optionalParams.LocationNo || null,
            MenuFlowId: menuFlowDetails ? menuFlowDetails.MenuFlowId : optionalParams.MenuFlowId || null,
            DisplayName: optionalParams.DisplayName || menuFlowDetails.MenuFlowDescription || null,
            Quantity: optionalParams.hasOwnProperty('Quantity') ? optionalParams.Quantity : Number.isNaN(optionalParams.Quantity) ? 1 : optionalParams.Quantity,
            DietaryTags: optionalParams.DietaryTags || null,
            Tags: optionalParams.Tags || null,
            Kilojoules: optionalParams.Kilojoules || null,
            PosDisplay: optionalParams.PosDisplay || menuFlowDetails.MenuFlowDescription || menuFlowDetails.MenuFlowName || null /* UPSELL CASE?? */,
            PosDescription: optionalParams.PosDescription || menuFlowDetails.MenuFlowDescription || menuFlowDetails.MenuFlowName || null /* UPSELL CASE? */,
            CustomerFriendlyName: menuFlowDetails.CustomerFriendlyName || null,
            CustomerFriendlyDescription: menuFlowDetails.CustomerFriendlyDescription || null,
            MenuFlowName: menuFlowDetails ? menuFlowDetails.MenuFlowName : optionalParams.MenuFlowName || null,
            MenuFlowNotes: menuFlowDetails ? menuFlowDetails.MenuFlowNotes : optionalParams.MenuFlowNotes || null,
            UnitTotalValue: optionalParams.hasOwnProperty('UnitTotalValue')
                ? optionalParams.UnitTotalValue
                : Number.isNaN(optionalParams.UnitTotalValue)
                    ? null
                    : optionalParams.UnitTotalValue,
            UnitPrice: optionalParams.hasOwnProperty('UnitPrice')
                ? optionalParams.UnitPrice
                : menuFlowDetails.OverridePrice
                    ? menuFlowDetails.OverridePrice
                    : Number.isNaN(optionalParams.UnitPrice)
                        ? null
                        : optionalParams.UnitPrice,
            Pages: menuFlowDetails ? Items.createMenuFlowItemPagesFromMenuFlowDetailsModel<OLO.Ordering.IMenuFlowItemPageProduct>(menuFlowDetails) : null,
        };
    }

    public static createMenuFlowItemPagesFromMenuFlowDetailsModel<T>(menuFlowDetails: IMenuFlowDetailsModel): OLO.Ordering.IMenuFlowItemPage<T>[] {
        /* Create ICartMenuFlowPage or IWizzardMenuFlowPage from regular menuFlow - restore pages container */
        return menuFlowDetails.Pages.map((page) => ({
            _AutoAddProducts: [],
            SpecialPriceQuantity: page.SpecialPriceQuantity,
            SpecialPrice: page.SpecialPrice,
            SpecialPriceSelection: page.SpecialPriceSelection,
            PageMode: page.PageMode,
            PriceChangeMode: page.PriceChangeMode,
            PriceChangeValue: page.PriceChangeValue,
            PageMinQuantity: page.PageMinQuantity,
            PageMaxQuantity: page.PageMaxQuantity,
            HideFromKiosk: page.HideFromKiosk || false,
            PageIdentifier: page.PageIdentifier,
            PageName: page.PageName,
            Products: [],
        }));
    }

    public static createMenuFlowItemFromOnlineOrder(
        menuFlowActivation: APICommon.IOnlineOrderMenuFlowActivation,
        menuFlowDetails: IMenuFlowDetailsModel,
        optionalParams: ICartMenuFlowParamsOptional<State.ICartMenuFlowPageProduct> = {},
    ): State.ICartMenuFlowExtended {
        /* Create ICartMenuFlow or IWizzardMenuFlow from OnlineOrderMenuFlowActivation and MenuFlowDetails */
        const generatedPages = Items.createMenuFlowItemPagesFromMenuFlowDetailsModel<State.ICartMenuFlowPageProduct>(menuFlowDetails);

        return {
            _IsDisabled: optionalParams._IsDisabled || false,
            _Id: optionalParams._Id || menuFlowActivation.Id || new Date().getTime() + Math.floor(Math.random() * 10000),
            IsUpsell: menuFlowActivation.IsUpsell || optionalParams.IsUpsell || false,
            DisplayName: menuFlowActivation.DisplayName || optionalParams.DisplayName || null,
            DisplayDescription: optionalParams.DisplayDescription || null,
            LocationNo: optionalParams.LocationNo || null,
            MenuFlowId: menuFlowActivation.MenuFlowId || optionalParams.MenuFlowId || null,
            Pages: optionalParams.Pages || generatedPages,
            Quantity: menuFlowActivation.Quantity || optionalParams.Quantity || 0,
            UnitPrice: menuFlowActivation.UnitPrice || optionalParams.UnitPrice || 0,
            UnitTotalValue: menuFlowActivation.Value || optionalParams.UnitTotalValue || 0,
            SpecialInstructions: menuFlowActivation.SpecialInstructions || optionalParams.SpecialInstructions,
            DietaryTags: optionalParams.DietaryTags || null,
            Tags: optionalParams.Tags || null,
            Kilojoules: optionalParams.Kilojoules || null,
            PosDisplay: menuFlowActivation.DisplayName || optionalParams.DisplayName || null,
            PosDescription: menuFlowActivation.DisplayDescription || optionalParams.DisplayDescription || null,
            MenuFlowNotes: menuFlowDetails.MenuFlowNotes || optionalParams.MenuFlowNotes || null,
        };
    }

    public static updateMenuFlowItemPrices(menuFlowDetails: IMenuFlowDetailsModel, menuFlow: State.ICartMenuFlow): State.ICartMenuFlow {
        /* Update prices in ICartMenuFlow */
        const priceObj: OLO.Ordering.IPricingMenuFlow = Pricing.calculatePricesFromMenuFlow(menuFlowDetails, menuFlow);

        menuFlow.UnitPrice = priceObj.UnitPrice;
        menuFlow.UnitTotalValue = priceObj.UnitTotalValue;

        menuFlow.Pages = menuFlow.Pages.map((Page) => {
            const pageFromPriceObj = priceObj.Pages.find((obj) => obj.PageIdentifier === Page.PageIdentifier);

            if (pageFromPriceObj) {
                return {
                    ...Page,
                    Products: Page.Products.map((Product) => {
                        const productFromPriceObj = pageFromPriceObj.Products.find(
                            (obj) => obj.ProductId === Product.ProductId && obj.PageProductIdentifier === Product.PageProductIdentifier,
                        );

                        if (productFromPriceObj) {
                            return {
                                ...Product,
                                UnitPrice: productFromPriceObj.UnitPrice,
                                TotalValue: productFromPriceObj.TotalValue,
                            };
                        }

                        return Product;
                    }),
                };
            }

            return Page;
        });

        return menuFlow;
    }

    public static convertToSanitizedSimpleItem(
        item: Partial<State.ICartSimpleItem & State.ICartSimpleItemExtended & OLO.Ordering.ISimpleItem & APICommon.IOnlineMenuProductResponseModel>,
        locationNo: number = null,
    ): OLO.Ordering.ISimpleItem {
        const converted: OLO.Ordering.ISimpleItem = {
            PosDisplay: item.PosDisplay || item.DisplayName,
            DisplayName: item.DisplayName,
            Kilojoules: item.Kilojoules,
            LocationNo: item.LocationNo || locationNo,
            MenuFlowId: null,
            Plu: item.Plu,
            Tags: item.Tags,
            PosDescription: item.PosDescription,
            ProductId: item.ProductId || null,
            Quantity: item.Quantity || 1,
            SpecialInstructions: item.SpecialInstructions || null,
            UnitPrice: typeof item.UnitPrice === 'number' ? item.UnitPrice : item.Price || 0,
            ProductCategoryId: item.ProductCategoryId || null,
            ProductFamilyIds: item.ProductFamilyIds || null,
            _Id: item._Id || null,
        };

        if (item._IsDisabled !== undefined && item._IsSelected !== undefined) {
            converted._IsReorder = true;
        }

        return converted;
    }

    public static convertToSanitizedMenuFlowItem(item: State.ICartMenuFlowExtended & State.ICartMenuFlow & OLO.Ordering.IMenuFlowItem): OLO.Ordering.IMenuFlowItem {
        const converted = { ...item };

        if (converted._IsDisabled !== undefined && converted._IsSelected !== undefined) {
            delete converted._IsDisabled;
            delete converted._IsSelected;

            converted._IsReorder = true;
        }

        return converted;
    }

    public static createSimpleItemFromOnlineOrderItemModel(
        item: APICommon.IOnlineOrderItemModel,
        optionalParams: OLO.Ordering.ISimpleItemParamsOptional = {},
    ): State.ICartSimpleItemExtended {
        const simpleItem: State.ICartSimpleItem = Items.convertToSanitizedSimpleItem({
            DisplayName: item['DisplayName'] || optionalParams.DisplayName || null,
            Kilojoules: item['Kilojoules'] || optionalParams.Kilojoules || null /* API Error - missing */,
            LocationNo: item['LocationNo'] || optionalParams.LocationNo || null,
            MenuFlowId: optionalParams.MenuFlowId || null,
            Plu: item.PLU || optionalParams.Plu || null,
            PosDescription: item['PosDescription'] || optionalParams.PosDescription || null /* ! API Error - missing */,
            PosDisplay: item['PosDisplay'] || optionalParams.PosDisplay || null /* ! API Error - missing */,
            ProductId: item.Id || optionalParams.Id,
            Quantity: item.Quantity || optionalParams.Quantity || 0,
            SpecialInstructions: item.SpecialInstructions || optionalParams.SpecialInstructions || null,
            UnitPrice: optionalParams.hasOwnProperty('UnitPrice') ? optionalParams.UnitPrice : item.UnitPrice || 0,
            _Id: optionalParams._Id || item.Id || new Date().getTime() + Math.floor(Math.random() * 10000),
        });

        return {
            ...simpleItem,
            _IsDisabled: optionalParams._IsDisabled || false,
            _IsSelected: optionalParams._IsSelected || false,
        };
    }

    public static generateIngredientsDescriptionForProduct(product: APICommon.IOnlineOrderMenuFlowItem | State.ICartMenuFlowPageProduct) {
        if (!product) return null;

        if (product.IngredientsChanges?.IngredientsModified?.length > 0) {
            const ingredientsModified = product.IngredientsChanges.IngredientsModified as APICommon.IOnlineOrderItemIngredientModification[];
            const ingredients: string[] = ingredientsModified.reduce((acc, ingredient) => [...acc, ingredient.ModifierName], [] as string[]);

            return (ingredients.length && ingredients.join(', ')) || '';
        }

        return '';
    }

    public static generateCartMenuSpecialInstructions(menuFlow: State.ICartSimpleItem): string {
        return menuFlow.SpecialInstructions ? menuFlow.SpecialInstructions : '';
    }

    public static generateCartMenuFlowDescription(menuFlow: State.ICartMenuFlow): string {
        const menuFlowType = MenuFlows.extractType(menuFlow.Tags);

        if (menuFlowType !== 'enhanced-menu-flow') {
            switch (menuFlowType) {
                case 'step-through-menu-flow':
                    return menuFlow.Pages.reduce((acc, page) => {
                        if (!page.Products.length) return acc;

                        const isSingleProductPage = page.PageMinQuantity === 1 && page.PageMaxQuantity === 1;

                        const productsDesc: string[] = page.Products.reduce((productAcc, product) => {
                            if (product._HideFromKiosk === true) return productAcc;

                            const ingredients: string = Items.generateIngredientsDescriptionForProduct(product);
                            const ingredientsWithSpace = (ingredients && ` ${ingredients}`) || '';

                            if (isSingleProductPage /*  || product.Quantity === 1 */) {
                                return [...productAcc, `With ${product.ProductName}${ingredientsWithSpace}`];
                            }

                            return [...productAcc, `${product.Quantity} x ${product.ProductName}${ingredientsWithSpace}`];
                        }, []).filter((arr) => arr.length > 0);

                        if (!productsDesc.length) return acc;

                        return [...acc, ...productsDesc];
                    }, []).join(', ');

                default:
                    return menuFlow.Pages.reduce((acc, page) => {
                        let productsArrDesc: Array<Array<string>> = [
                            page._AutoAddProducts.reduce((productAcc, product) => {
                                const exists = page.Products.find((obj) => obj.Plu === product.Plu && product._HideFromKiosk !== true);
                                if (exists || (!exists && page.PageMinQuantity === 1 && page.PageMaxQuantity === 1)) return productAcc as string[];

                                return [...productAcc, `No ${product.ProductName}`];
                            }, [] as string[]),

                            page.Products.reduce((productAcc, product) => {
                                if (product._HideFromKiosk === true || product.Quantity === product.KioskAutoAddQty) return productAcc;

                                const ingredients: string = Items.generateIngredientsDescriptionForProduct(product);
                                const ingredientsWithSpace = (ingredients && ` ${ingredients}`) || '';

                                if (!product.KioskAutoAddQty) {
                                    /* NEW */
                                    if (product.Quantity === 1) {
                                        const ommitPrefix: boolean = page.PageMinQuantity === 1 && page.PageMaxQuantity === 1;

                                        return [...productAcc, `${ommitPrefix ? '' : 'Add '}${product.ProductName}${ingredientsWithSpace}`];
                                    } else {
                                        return [...productAcc, `${product.Quantity} x ${product.ProductName}${ingredientsWithSpace}`];
                                    }
                                }

                                if (product.Quantity < product.KioskAutoAddQty) {
                                    /* Less */
                                    return [...productAcc, `Less ${product.ProductName}${ingredientsWithSpace}`];
                                }

                                if (product.Quantity === product.KioskAutoAddQty + 1) {
                                    return [...productAcc, `Extra ${product.ProductName}${ingredientsWithSpace}`];
                                }

                                return [...productAcc, `${product.Quantity} x Extra ${product.ProductName}${ingredientsWithSpace}`];
                            }, []),
                        ].filter((arr) => arr.length > 0);

                        if (productsArrDesc.length === 0) return acc;

                        return [...acc, ...productsArrDesc];
                    }, [])
                        .map((arr) => arr.join(', '))
                        .join(', ');
            }
        }

        return menuFlow.Pages.reduce((acc, page) => {
            const productsDesc: string[] = page.Products.reduce((productAcc, product) => {
                if (product._HideFromKiosk === true) {
                    return productAcc;
                }
                const ingredients: string = Items.generateIngredientsDescriptionForProduct(product);
                const ingredientsWithSpace = (ingredients && ` ${ingredients}`) || '';

                return [
                    ...productAcc,
                    product.Quantity > 1 ? `${product.Quantity} x ${product.ProductName}${ingredientsWithSpace}` : `${product.ProductName}${ingredientsWithSpace}`,
                ];
            }, []).filter((arr) => arr.length > 0);

            if (!productsDesc.length) return acc;

            return [...acc, ...productsDesc];
        }, []).join(', ');
    }

    public static generateOnlineOrderMenuFlowDescription(menuFlowActivation: APICommon.IOnlineOrderMenuFlowActivation): string {
        return menuFlowActivation.MenuFlowItems.reduce((acc, item) => {
            const ingredients: string = Items.generateIngredientsDescriptionForProduct(item);
            const ingredientsWithSpace = (ingredients && ` ${ingredients}`) || '';

            return [...acc, item.Quantity > 1 ? `${item.Quantity} x ${item.DisplayName}${ingredientsWithSpace}` : `${item.DisplayName}${ingredientsWithSpace}`];
        }, []).join(', ');
    }
}

export interface ICartMenuFlowParamsOptional<T> {
    _IsDisabled?: boolean;
    _Id?: number;
    IsUpsell?: null | boolean;
    DisplayName?: string;
    DisplayDescription?: string;
    LocationNo?: number;
    MenuFlowId?: number;
    Pages?: OLO.Ordering.IMenuFlowItemPage<T>[];
    Quantity?: number;
    UnitPrice?: number;
    UnitTotalValue?: number;
    SpecialInstructions?: string;
    DietaryTags?: APICommon.ITagModel[];
    Tags?: APICommon.ITagModel[];
    Kilojoules?: number;
    PosDisplay?: string;
    PosDescription?: string;
    MenuFlowNotes?: string;
    MenuFlowName?: string;
}
