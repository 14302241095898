export class PaymentForms {
    public static mapEnumFormType(formType: OLO.Enums.PAYMENT_FORM_TYPE): Nullable<OLO.Types.PAYMENT_METHOD_FORM_TYPE> {
        switch(formType) {
            case OLO.Enums.PAYMENT_FORM_TYPE.PROVIDER_FORM:
                return 'withVendorForm';
            case OLO.Enums.PAYMENT_FORM_TYPE.REDIRECT:
                return 'redirect';
            case OLO.Enums.PAYMENT_FORM_TYPE.COMMON:
                return 'common';
            default:
                return null;
        }
    }
}
