import { ILoginMemberRequest, IMemberModel, IValidateMemberLoginRequest, IValidateMemberLoginResponse } from '@shared/state';

export class AuthMapper {
    public static mapSignInPostRequest(credentials: ILoginMemberRequest): APIv3.LoginMemberRequest {
        return credentials ? {
            Login: credentials.Login,
            Password: credentials.Password,
            LoginType: credentials.LoginType,
            MobilePhoneCountryId: credentials.MobilePhoneCountryId,
        } : null;
    }

    public static mapSignInPostResponse(loginJWT: APIv3.JwtTokenResponse): OLO.Authorization.IJWTokenLogin {
        if (loginJWT) {
            return {
                jwt: {
                    AccessToken: loginJWT.AccessToken,
                    RefreshToken: loginJWT.RefreshToken
                },
                sessionKey: 'temp-session-key'
            };
        }

        return null;
    }

    public static mapSignUpPostRequest(member: IMemberModel): APIv3.MemberSignUpRequestModel {
        return member ? {
            PartialMemberId: member.PartialMemberId,
            MemberName: member.MemberName,
            MemberSurname: member.MemberSurname,
            MemberGroupId: member.MemberGroupId,
            Email: member.Email,
            MobilePhone: member.MobilePhone,
            MobilePhoneCountryId: member.MobilePhoneCountryId,
            TierId: member.TierId,
            Birthday: member.Birthday,
            SexId: member.SexId,
            Password: member.Password,
            FavoriteLocationId: member.FavoriteLocationId,
            HomeLocationNo: member.HomeLocationNo,
            HomeVenueNo: member.HomeVenueNo,
            ReceivePromoAllowed: member.ReceivePromoAllowed,
            MobilePushNotificationsAllowed: member.MobilePushNotificationsAllowed,
            IsSMSMarketingAllowed: member.IsSMSMarketingAllowed,
            IsEmailValidated: member.IsEmailValidated,
            IsMobileValidated: member.IsMobileValidated,
            IsOnlineRegistered: member.IsOnlineRegistered,
        } : null;
    }

    public static mapSignUpPostResponse(response: APIv3.AuthSignUpMember.Responses.$201, member: IMemberModel): IMemberModel {
        if (response) {
            return member.PartialMemberId ? {
                ...member,
                MemberId: member.PartialMemberId
            } : {
                ...member
            };
        }

        return null;
    }

    public static mapSignUpSimpleResponse(signUpSimple: APIv3.AuthSignUpMember.Responses.$201): boolean {
        return Boolean(signUpSimple);
    }

    public static mapVerifyPhoneNumberRequest(phoneNumber: { LoyaltyAppId: number; PhoneNumber: string; PhoneNumberCountryID: number; }): APIv3.PhoneTemporaryCodeRequest {
        return phoneNumber ? {
            PhoneNumber: phoneNumber.PhoneNumber,
            LoyaltyAppId: phoneNumber.LoyaltyAppId,
            PhoneNumberCountryID: phoneNumber.PhoneNumberCountryID
        } : null;
    }

    public static mapVerifyPhoneNumberResponse(verifyPhoneNumber: APIv3.AuthCreateTemporaryCode.Responses.$201): boolean {
        return verifyPhoneNumber;
    }

    public static mapVerifyPhoneNumberTokenRequest(
        phoneNumberToken: { Token: string; PhoneNumber: string; },
        PhoneNumberCountryPrefix: number
    ): APIv3.PhoneTemporaryCodeValidationRequest {
        return phoneNumberToken ? {
            PhoneNumber: phoneNumberToken.PhoneNumber,
            Token: phoneNumberToken.Token,
            PhoneNumberCountryPrefix
        } : null;
    }

    public static mapVerifyPhoneNumberTokenResponse(verifyPhoneNumberToken: APIv3.AuthValidateTemporaryCode.Responses.$200): boolean {
        return verifyPhoneNumberToken;
    }

    public static mapDeleteMemberAccountResponse(deleteMemberAccount: APIv3.MembersClearMemberPersonalData.Responses.$200): boolean {
        return deleteMemberAccount;
    }

    public static mapValidateLoginPostRequest(validateMemberLogin: IValidateMemberLoginRequest): APIv3.ValidateMemberLoginRequest {
        return validateMemberLogin ? {
            Login: validateMemberLogin.Login,
            LoginType: validateMemberLogin.LoginType,
            MobilePhoneCountryId: validateMemberLogin.MobilePhoneCountryId,
        } : null;
    }

    public static mapValidateLoginResponse(validateLogin: APIv3.AuthValidateMemberLogin.Responses.$200): IValidateMemberLoginResponse {
        return validateLogin ? {
            Id: validateLogin.Id,
            IsMobileValidated: validateLogin.IsMobileValidated,
            IsEmailValidated: validateLogin.IsEmailValidated,
            IsOnlineRegistered: validateLogin.IsOnlineRegistered,
        } : null;
    }
}
