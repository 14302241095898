import { Injectable, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { PaymentMethodCreateParams } from '@stripe/stripe-js';

import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';
import * as selectors from '@shared/state/selectors';
import * as actions from '@shared/state/actions';

import { CreditCardsMapper } from '@shared/core/mappers/credit-cards.shared.mapper';
import * as State from '@shared/state';
import * as Services from '@shared/core/services';

import { Observable, of } from 'rxjs';
import { map, withLatestFrom, take, filter, combineLatest } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CreditCardsController {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _store: Store<State.IStateShared>,
        private _paymentsService: Services.PaymentsService,
        private _creditCardsService: Services.CreditCardsService,
    ) { }
    public getStripeConfig$(): Observable<State.StripeLocationConfig> {
        return this._store
            .pipe(
                select(selectors.getStripeLocationConfig)
            );
    }

    public getAdyenConfig$(): Observable<State.AdyenLocationConfig> {
        return this._store
            .pipe(
                select(selectors.getAdyenLocationConfig)
            );
    }

    public initAdyen(): void {
        this._store.dispatch(actions.CreditCardsAdyenInit());
    }


    public initStripe(): void {
        this._store.dispatch(actions.CreditCardsStripeInit());
    }

    public getStripeMemberBillingDetails$(): Observable<PaymentMethodCreateParams.BillingDetails> {
        return this._store
            .pipe(
                select(selectors.getMemberState),
                withLatestFrom(
                    this._store
                        .pipe(
                            select(selectors.isMemberAuthorizedJWT)
                        ),
                    this._store
                        .pipe(
                            select(selectors.getGuestData)
                        )
                ),
                map(([{ data }, isAuthorized, guestDetails]) => {
                    const model: PaymentMethodCreateParams.BillingDetails = {};
                    if(!isAuthorized) {
                        model.name = `${guestDetails.FirstName} ${guestDetails.LastName}`;
                        model.email = `${guestDetails.Email}`;
                        model.phone = `${guestDetails.Prefix}${guestDetails.MobileNumber}`;
                    } else {
                        model.name = `${data.MemberName} ${data.MemberSurname}`;
                        model.email = `${data.Email}`;
                        model.phone = `${data.MobilePhonePrefix}${data.MobilePhone || data.MobileNumber || data.HomePhone}`;
                    }

                    return model;
                })
            );
    }


    public isAddingCard$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isAddingCardRequest),
            );
    }

    public isDownloadingCardsList$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isDownloadingCardsList)
            );
    }

    public isMakingRequests$(mapToLoadingStatus: boolean = false): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isLoadingCards),
            );
    }

    public isMakingRequestsMapped$(): Observable<OLO.Components.LOADING_STATUS> {
        return this.isMakingRequests$()
            .pipe(
                map(isLoading => isLoading ? 'loading' : null)
            );
    }

    public showPaymentFormForGuest$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.shouldShowCreditCardForm),
                combineLatest(
                    this._store
                        .pipe(
                            select(selectors.showAddCardForm)
                        )
                ),
                map(([shouldShowForm, formIsAddingFlag]) => {
                    if(this._paymentsService.isSpecialPaymentMethodAvailable) {
                        return false;
                    }

                    return formIsAddingFlag || shouldShowForm;
                }),
            );
    }

    public addCreditCardWithOrder(card: OLO.Members.IMemberCreditCardDetails): void {
        this._store.dispatch(actions.CreditCardsStoreWithOrder({ card }));
        this._store.dispatch(actions.SelectActiveCreditCardId({ cardId: card.Id }));
        this.hideAddCardForm();
    }

    public showPaymentFormForMember$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.getCardState),
                withLatestFrom(
                    this.isMakingRequests$(),
                    this._store
                        .pipe(
                            select(selectors.showPaymentFormForMember(this._config))
                        )
                ),
                /* Filter this to avoid form disconnection during post */
                filter(([state, isMakingRequests]) => !state.activeCardRedirectUrl && isMakingRequests === false),
                map(([,, showForm]) => showForm)
            );
    }

    public setActiveCardBillingDetails(billingDetails: OLO.CreditCards.CreditCardBillingDetails): void {
        this._store
            .pipe(
                select(selectors.getActiveCardDetails),
                withLatestFrom(
                    this._store.pipe(select(selectors.getLoyaltyAppSettings))
                ),
                take(1)
            ).subscribe(([card, { data }]) => {
                if(!card) return;

                if(billingDetails) {
                    billingDetails = Utils.CreditCards.normalizeBillingDetailsCountryCode(billingDetails, data.CountryAssignments);
                }

                this._store.dispatch(actions.SetCardBillingAddress({ cardId: card.Id, billingDetails }));
            });
    }

    public canChangePaymentDetailsForGuest$(): Observable<boolean> {
        return this.showPaymentFormForGuest$()
            .pipe(
                map(showForm => {
                    const isCardTypePaymentAvailabile = this._config.payments.baseProvider !== null;

                    if(isCardTypePaymentAvailabile && this._paymentsService.isSpecialPaymentMethodAvailable) {
                        return false;
                    }

                    return isCardTypePaymentAvailabile ? !showForm : false;
                }),
            );
    }

    public canGuestAddAnotherCard$(): Observable<boolean> {
        return of((this._paymentsService.defaultVendorPaymentProvider !== null || this._config.payments.payInStore === true) && this._config.payments.baseProvider !== null);
    }

    public canMemberAddFirstCard$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.canMemberAddFirstCard(this._config)),
            );
    }

    public canMemberAddAnotherCard$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.canMemberAddAnotherCard(this._config)),
            );
    }

    public isAccountChargeSet$(config: IConfig): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isAccountSet(config))
            );
    }


    public getCardsList$(): Observable<OLO.Members.IMemberCreditCardDetails[]> {
        return this._store
            .pipe(
                select(selectors.getCards),
            );
    }

    public getActiveCardDetails$(): Observable<OLO.Members.IMemberCreditCardDetails> {
        return this._store
            .pipe(
                select(selectors.getActiveCardDetails)
            );
    }

    public isCardSelected$(creditCard: OLO.Members.IMemberCreditCardDetails): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isCardSelected(creditCard)),
            );
    }

    public cardErrorMessages(creditCard: OLO.Members.IMemberCreditCardDetails): { [key: string]: string; } {
        if (creditCard.Id) return { cardRetry: null };

        return { cardChange: null };
    }

    public selectedCardIsOk$(creditCard: OLO.Members.IMemberCreditCardDetails): Observable<boolean> {
        return this.isCardSelected$(creditCard)
            .pipe(
                combineLatest(
                    this._store
                        .pipe(
                            select(selectors.getPaymentErrors)
                        ),
                    this._store
                        .pipe(
                            select(selectors.isGuestModeEnabled)
                        ),
                    this._store
                        .pipe(
                            select(selectors.getCardState)
                        )
                ),
                map(([isSelected, errors, guestMode, cardsState]) => {
                    if (!guestMode) return isSelected && errors.length === 0;

                    if (isSelected && cardsState.validation.hasFailed === true || errors.length > 0) return false;

                    return isSelected;
                })
            );
    }

    public selectedCardHasError$(creditCard: OLO.Members.IMemberCreditCardDetails): Observable<boolean> {
        return this.isCardSelected$(creditCard)
            .pipe(
                combineLatest(
                    this._store
                        .pipe(
                            select(selectors.getPaymentErrors)
                        ),
                    this._store
                        .pipe(
                            select(selectors.isGuestModeEnabled)
                        ),
                    this._store
                        .pipe(
                            select(selectors.getCardState)
                        )
                ),
                map(([isSelected, errors, guestMode, cardsState]) => {
                    const savedHasError = !guestMode && isSelected && errors.length !== 0;

                    const validationFailed: boolean = creditCard.ValidationStatus === 'error' && isSelected;
                    const removeFailed: boolean = cardsState.remove.id !== null && cardsState.remove.id === creditCard.Id && cardsState.remove.hasFailed;
                    if (validationFailed || removeFailed || savedHasError) return true;

                    return false;
                }),
            );
    }

    public getActiveCardRedirectUrl$(): Observable<string> {
        return this._store
            .pipe(
                select(selectors.getActiveCardRedirectUrl)
            );
    }

    public canCancelAddingNewCardsForm$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.hasCreditCardsDefined),
                combineLatest(
                    this._store
                        .pipe(
                            select(selectors.getCardState)
                        )
                ),
                map(([hasCardsDefined, state]) => {
                    if (state.activeCardRedirectUrl && state.data && state.data.length === 1) return false;

                    return hasCardsDefined;
                })
            );
    }

    public isLoadingCardsFirstTime$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isRequestingCardsFirstTime),
            );
    }

    public hasDownloadedCards$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.hasDownloadedCards),
            );
    }

    public hasCards$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.getCardState),
                combineLatest(
                    this.isLoadingCardsFirstTime$()
                ),
                filter(([state, isLoadingFirstTime]) => isLoadingFirstTime === false),
                map(([state, isLoadingFirstTime]) => state.data && state.data.length > 0)
            );
    }

    public isRemovingCard(creditCard: OLO.Members.IMemberCreditCardDetails): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isRemovingCard(creditCard.Id))
            );
    }

    public removeCardFailed(creditCard: OLO.Members.IMemberCreditCardDetails): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.removeFailedForCard(creditCard.Id))
            );
    }

    public canShowNoPaymentMethodDefined$(): Observable<boolean> {
        return this.hasCards$()
            .pipe(
                combineLatest(
                    this.hasDownloadedCards$(),
                    this._store
                        .pipe(
                            select(selectors.showAccountPaymentMethod(this._config))
                        ),
                ),
                map(([hasCards, hasDownloaded, hasAccountPaymentMethod]) => hasDownloaded === true && hasCards === false && !hasAccountPaymentMethod)
            );
    }

    public resetCreditCardErrors(): void {
        this._store.dispatch(actions.CreditCardsResetError());
    }

    public addCreditCard(cardData: OLO.CreditCards.ICreditCardDetails): void {
        this._store.dispatch(actions.GetCreditCardToken(cardData));
    }

    public hideAddCardForm(): void {
        this._store.dispatch(actions.CreditCardShowForm({ isAdding: false }));
    }

    public toggleCreditCardFormVisibilityWithCleanUp(isAdding: boolean = true): void {
        this._store.dispatch(actions.CreditCardsClearAllUnsavedCards());
        this._store.dispatch(actions.CreditCardShowForm({ isAdding }));
    }

    public removeAllUnsavedCards(): void {
        return this._store.dispatch(actions.CreditCardsClearAllUnsavedCards());
    }

    public resetCreditCardDetails(): void {
        this._store
            .pipe(
                select(selectors.isPaying),
                take(1)
            ).subscribe(isPaying => {
                if (isPaying) return;

                this._store.dispatch(actions.PaymentReset());
                this._store.dispatch(actions.CreditCardsStateReset());
            });
    }

    public removeUnsavedSelectedCard(): void {
        this._store
            .pipe(
                select(selectors.getCardState),
                take(1)
            ).subscribe(state => {
                const activeToken = state.activeCardToken;
                const card = state.data.find(obj => obj.Token === activeToken && obj.Id === null);

                if (!card) return;

                this._store.dispatch(actions.CreditCardsRemoveUnsavedCard({ token: activeToken }));
            });
    }

    public selectDefaultVendorPaymentMethod(): void {
        if(this._paymentsService.isVendorPaymentProviderAvailable) {
            this._store
                .pipe(
                    select(selectors.getActiveCardId),
                    withLatestFrom(
                        this._store
                            .pipe(
                                select(selectors.getActiveCardToken)
                            )
                    ),
                    take(1)
                ).subscribe(([cardId, cardToken]) => {
                    if(!cardId && !cardToken) {
                        this.selectCardForPayment({ Id: this._paymentsService.defaultVendorPaymentProvider });
                    }
                });
        }
    }

    public selectCardForPayment(card: OLO.Members.IMemberCreditCardDetails): void {
        this._store
            .pipe(
                select(selectors.isPaying),
                take(1)
            ).subscribe(isPaying => {
                if (isPaying) return;

                this._store.dispatch(actions.PaymentReset());
                this._store.dispatch(actions.CreditCardsResetError());
                if (card?.Id) {
                    return this._store.dispatch(actions.SelectActiveCreditCardId({ cardId: card.Id }));
                }
                if (card?.Token) {
                    return this._store.dispatch(actions.SelectActiveCreditCardToken({ token: card.Token }));
                }
            });
    }

    public requestCardsList(): void {
        this._store
            .pipe(
                select(selectors.getMemberState),
                filter(state => state.isDownloading === false),
                withLatestFrom(
                    this._store
                        .pipe(
                            select(selectors.isMemberAuthorizedJWT)
                        ),
                    this._store
                        .pipe(
                            select(selectors.isGuestModeEnabled)
                        ),
                ),
                take(1),
            ).subscribe(([state, isAuthorized, isGuest]) => {
                if (isAuthorized && !isGuest) {
                    this._store.dispatch(actions.CreditCardsRequest());
                } else {
                    this._store.dispatch(actions.CreditCardsSelectDefaultPaymentMethod());
                }
            });
    }

    public removeCreditCard(card: OLO.Members.IMemberCreditCardDetails): void {
        return this._store.dispatch(actions.CreditCardsRemoveRequest({ cardId: card.Id }));
    }

    // public memberCardErrorHandler(card: OLO.Members.IMemberCreditCardDetails): void {
    //     this._store.dispatch(actions.PaymentReset());
    // }

    public async memberCardErrorHandler(): Promise<void> {
        return new Promise((resolve) => {
            this._store.pipe(select(selectors.getNonTokenizedCardWithError), take(1)).subscribe((nonTokenizedCardWithError) => {
                this._store.dispatch(actions.PaymentReset());
                if (nonTokenizedCardWithError) {
                    const card = CreditCardsMapper.mapMemberCreditCardToToloCreditCardDetail(nonTokenizedCardWithError);

                    switch (true) {
                        case this._paymentsService.isCommonType:
                            return resolve(this.addCreditCard(card));
                        case this._paymentsService.isRedirectType:
                            return resolve(this._creditCardsService.addCreditCardWithRedirect(card));

                        default:
                            console.error('Unsupported case for retry');
                    }
                }

                resolve();
            });
        });
    }

    public isAccountChargeSelected$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isAccountChargeSelected)
            );
    }

    public isPayInStoreSelected$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isPayInStoreSelected)
            );
    }
}
