import { Injectable, Inject } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Utils from '@shared/core/utils';
import * as Services from '@shared/core/services';
import * as Tokens from '@shared/core/tokens';
import * as Models from '@shared/core/models';

import * as StateModels from '../interface';

import { never, Observable, of } from 'rxjs';
import { take, map, catchError, withLatestFrom, switchMap, filter, auditTime } from 'rxjs/operators';
import { IActivateVoucherRequest, IDeactivateVoucherRequest, IOnlineOrderDetailedBusinessModel } from '../interface';

@Injectable()
export class OnlineOrdersEffects {
    @Effect() public saveOrderConfirmationUrl$: Observable<Action> = this._actions$.pipe(
        ofType(actions.OnlineOrderSaveConfirmationUrlRequest),
        switchMap(({ orderId, url }) => {
            const model: Models.OnlineOrderUrlModel = new Models.OnlineOrderUrlModel(orderId, url);

            return this._onlineOrdersService.insertOnlineOrderUrl(model).pipe(
                map((payload) =>
                    actions.OnlineOrderSaveConfirmationUrlSuccessRequest({
                        orderId,
                        url,
                        payload,
                    }),
                ),
                catchError((ex) => {
                    console.error('Unable to save order\'s confirmation url', ex);

                    return of(
                        actions.OnlineOrderSaveConfirmationUrlErrorRequest({
                            orderId,
                            url,
                            ex,
                        }),
                    );
                }),
            );
        }),
    );

    @Effect() onOnlineOrderCreateRequest$: Observable<any> = this._actions$.pipe(
        ofType(actions.OnlineOrderCreateRequest),
        withLatestFrom(
            this._store.select(selectors.getGuestData),
            this._store.select(selectors.getCurrentMember),
            this._store.select(selectors.isZeroPricedOrder),
            this._store.select(selectors.isPayInStoreSelected),
        ),
        switchMap(([action, guestData, memberData, isZeroPricedOrder, isPayInStoreSelected]) =>
            this._store.pipe(
                select(selectors.getOnlineOrderRecalcData),
                filter((calculatedOrder) => calculatedOrder.isRecalculating === false),
                take(1),
                withLatestFrom(
                    this._store.pipe(select(selectors.getOnlineOrderState)),
                    this._store.pipe(select(selectors.getLoyaltyAppId))
                ),
                switchMap(([calculatedOrder, orderState, LoyaltyAppId]) => {
                    if (calculatedOrder.hasFailed || !calculatedOrder.data) throw new Error('Invalid recalculated order data');


                    if (guestData?.MobilePhoneCountryId) {
                        guestData.MobileNumberCountryID = guestData.MobilePhoneCountryId;
                        delete guestData?.MobilePhoneCountryId;
                    }

                    const MemberId = memberData ? memberData.UserId || memberData.MemberId : null;

                    const Status = isZeroPricedOrder || isPayInStoreSelected || this._config.demoMode === true
                        ? OLO.Enums.ONLINE_ORDER_STATUS.VALIDATED : calculatedOrder.data.Status;

                    const model: IOnlineOrderDetailedBusinessModel = {
                        ...calculatedOrder.data,
                        Id: null,
                        MemberId: MemberId || null,
                        PartialMember: guestData || null,
                        LoyaltyAppId,
                        Status,
                        SendReceiptOnEmail: this._config.onlineOrders?.sendAutoReceiptEmail === true,
                        ReceiptNotificationEmailAdresses: [
                            guestData?.Email || memberData?.Email
                        ].filter(obj => obj),
                        ...Utils.OnlineOrders.extendWithOrderTypeSurcharges(calculatedOrder.data, orderState.orderType, false)
                    };

                    return this._onlineOrdersService.createNewOnlineOrder(model).pipe(map((payload) => actions.OnlineOrderCreateSuccessRequest({ payload })));
                }),
                catchError((ex) => of(actions.OnlineOrderCreateErrorRequest({ order: null, ex }))),
            ),
        ),
    );

    @Effect() onOnlineOrderCreateRequestSuccess$: Observable<any> = this._actions$.pipe(
        ofType(actions.OnlineOrderCreateSuccessRequest),
        withLatestFrom(this._store.pipe(select(selectors.isMemberAuthorizedJWT))),
        switchMap(([action, isAuthorized]) => {
            if (!isAuthorized) {
                const historyOrdersCache: StateModels.IOnlineOrderDetailedBusinessModel[] = JSON.parse(
                    this._cryptoService.decrypt(Utils.Storage.getItem(OLO.Enums.HISTORY_ORDERS_STORAGE.DATA))
                );
                const updatedHistoryOrdersCache = [...historyOrdersCache, action.payload];
                /* Requirement that we should keep only 5 orders in cache - TOLO-2565 */
                const newHistoryOrdersCache = updatedHistoryOrdersCache.length > 5
                    ? updatedHistoryOrdersCache.slice(1)
                    : updatedHistoryOrdersCache;

                Utils.Storage.set(OLO.Enums.HISTORY_ORDERS_STORAGE.DATA, this._cryptoService.encrypt(JSON.stringify(newHistoryOrdersCache)));
            }

            return [];
        })
    );

    @Effect() onOnlineOrderSendConfirmationEmail$: Observable<any> = this._actions$.pipe(
        ofType(actions.OnlineOrderSendConfrimationEmailRequest),
        switchMap((action) =>
            this._onlineOrdersService.sendEmailWithOrderConfirmation(action.orderId).pipe(
                map((result) => (result ? actions.OnlineOrderSendConfrimationEmailSuccessRequest({ result }) : actions.OnlineOrderSendConfrimationEmailErrorRequest({}))),
                catchError((ex) => of(actions.OnlineOrderSendConfrimationEmailErrorRequest({ ex }))),
            ),
        ),
    );

    @Effect() onOnlineOrderRecalculateRequest$: Observable<any> = this._actions$.pipe(
        ofType(actions.OnlineOrderRecalculateRequest),
        switchMap(() => this._store
            .pipe(
                select(selectors.isDownloadingAnyOrderTypes),
                filter(isDownloading => isDownloading === false),
                take(1),
                withLatestFrom(
                    this._store.select(selectors.getCart),
                    this._store.select(selectors.getCartTotalValue),
                    this._store.select(selectors.getCurrentMember),
                    this._store.pipe(select(selectors.getOnlineOrderState)),
                    this._store.pipe(select(selectors.getLoyaltyAppId)),
                ),
                switchMap(([action, cart, cartTotalValue, member, orderState, LoyaltyAppId]) => {
                    const _tempOrderModel = Utils.OnlineOrders.convertCart(this._config.saleName, cart, cart.pickupTime, {
                        MemberId: member ? member.MemberId : null,
                        OnlineOrderType: this._config.onlineOrders.OnlineOrderType,
                        RemoveModifiers: this._config.onlineOrders && this._config.onlineOrders.allowModifiers === false,
                    });
                    const orderModel: IOnlineOrderDetailedBusinessModel = {
                        ..._tempOrderModel,
                        ...Utils.OnlineOrders.extendWithOrderTypeSurcharges(_tempOrderModel, orderState.orderType, true),
                        LoyaltyAppId,
                        ActivatedVouchers: orderState.data?.ActivatedVouchers || cart.activatedVouchers
                    };

                    return this._onlineOrdersService.recalculateOnlineOrder(orderModel).pipe(
                        map((payload) => actions.OnlineOrderRecalculateSuccessRequest({ payload })),
                        catchError((ex) => of(actions.OnlineOrderRecalculateErrorRequest({ order: orderModel, ex }))),
                    );
                })
            ))
        ,
    );

    @Effect() onOnlineOrderAddVoucherRequest$: Observable<any> = this._actions$.pipe(
        ofType(actions.OnlineOrderAddVoucherRequest),
        switchMap((AddVoucherRequestAction) => this._store
            .pipe(
                select(selectors.isDownloadingAnyOrderTypes),
                filter(isDownloading => isDownloading === false),
                take(1),
                withLatestFrom(
                    this._store.select(selectors.getOnlineOrderVoucherCode),
                    this._store.select(selectors.getCart),
                    this._store.select(selectors.getCurrentMember),
                    this._store.pipe(select(selectors.getOnlineOrderState)),
                    this._store.pipe(select(selectors.getLoyaltyAppId)),
                ),
                switchMap(([action, VoucherCode, cart, member, orderState, LoyaltyAppId]) => {
                    const _tempOrderModel = Utils.OnlineOrders.convertCart(this._config.saleName, cart, cart.pickupTime, {
                        MemberId: member ? member.MemberId : null,
                        OnlineOrderType: this._config.onlineOrders.OnlineOrderType,
                        RemoveModifiers: this._config.onlineOrders && this._config.onlineOrders.allowModifiers === false,
                    });
                    const orderModel: IActivateVoucherRequest = {
                        Order: {
                            ..._tempOrderModel,
                            ...Utils.OnlineOrders.extendWithOrderTypeSurcharges(_tempOrderModel, orderState.orderType, true),
                            LoyaltyAppId,
                            ActivatedVouchers: orderState.data?.ActivatedVouchers || cart.activatedVouchers
                        },
                        VoucherCode,
                        ActivatedEntityId: null // TODO Check this value
                    };

                    return this._onlineOrdersService.addVoucherOnlineOrder(orderModel).pipe(
                        switchMap((payload) => {

                            this._modalsServicService.close(AddVoucherRequestAction.id);

                            return [
                                actions.OnlineOrderAddVoucherSuccessRequest({ code: VoucherCode, payload }),
                                actions.CartAddActivatedVoucher({ payload })
                            ];
                        }),
                        catchError((ex) => of(actions.OnlineOrderAddVoucherErrorRequest({ order: orderModel.Order, ex }))),
                    );
                })
            ))
        ,
    );

    @Effect() onOnlineOrderRemoveVoucherRequest$: Observable<any> = this._actions$.pipe(
        ofType(actions.OnlineOrderRemoveVoucherRequest),
        switchMap(() => this._store
            .pipe(
                select(selectors.isDownloadingAnyOrderTypes),
                filter(isDownloading => isDownloading === false),
                take(1),
                withLatestFrom(
                    this._store.select(selectors.getActiveVoucher),
                    this._store.select(selectors.getCart),
                    this._store.select(selectors.getCurrentMember),
                    this._store.pipe(select(selectors.getOnlineOrderState)),
                    this._store.pipe(select(selectors.getLoyaltyAppId)),
                ),
                switchMap(([action, voucher, cart, member, orderState, LoyaltyAppId]) => {
                    if (voucher) {
                        const _tempOrderModel = Utils.OnlineOrders.convertCart(this._config.saleName, cart, cart.pickupTime, {
                            MemberId: member ? member.MemberId : null,
                            OnlineOrderType: this._config.onlineOrders.OnlineOrderType,
                            RemoveModifiers: this._config.onlineOrders && this._config.onlineOrders.allowModifiers === false,
                        });

                        const orderModel: IDeactivateVoucherRequest = {
                            Order: {
                                ..._tempOrderModel,
                                ...Utils.OnlineOrders.extendWithOrderTypeSurcharges(_tempOrderModel, orderState.orderType, true),
                                LoyaltyAppId,
                                ActivatedVouchers: orderState.data?.ActivatedVouchers || cart.activatedVouchers
                            },
                            VoucherCode: voucher?.VoucherCode
                        };

                        return this._onlineOrdersService.removeVoucherOnlineOrder(orderModel).pipe(
                            switchMap((payload) => [
                                actions.OnlineOrderRemoveVoucherSuccessRequest({ payload }),
                                actions.CartRemoveActivatedVoucher()]
                            ),
                            catchError((ex) => of(actions.OnlineOrderRemoveVoucherErrorRequest({ order: orderModel.Order, ex }))),
                        );
                    } else {
                        return never();
                    }
                })
            ))
        ,
    );

    @Effect() onlineOrderRequest$: Observable<Action> = this._actions$.pipe(
        ofType(actions.OnlineOrderRequest),
        switchMap(({ orderId }) =>
            this._onlineOrdersService.getOnlineOrder(orderId).pipe(
                map((payload) => actions.OnlineOrderSuccessRequest({ payload })),
                catchError((ex) => of(actions.OnlineOrderErrorRequest({ orderId, ex }))),
            ),
        ),
    );

    @Effect() sendOrderReceipt$: Observable<Action> = this._actions$.pipe(
        ofType(actions.OnlineOrderSendEmailReceiptRequest),
        switchMap(({ orderId }) =>
            this._onlineOrdersService.sendOnlineOrderReceipt(orderId).pipe(
                map((response) => (response ? actions.OnlineOrderSendEmailReceiptSuccessRequest({ orderId }) : actions.OnlineOrderSendEmailReceiptErrorRequest({ orderId }))),
                catchError((ex) => {
                    console.log('Send order receipt error', ex);

                    return of(actions.OnlineOrderSendEmailReceiptErrorRequest({ orderId, ex }));
                }),
            ),
        ),
    );

    @Effect() resetReceiptEmailSendButton$: Observable<Action> = this._actions$.pipe(
        ofType(actions.OnlineOrderSendEmailReceiptSuccessRequest),
        auditTime(1000),
        switchMap(() => of(actions.OnlineOrderSendEmailReceiptSuccessReset()))
    );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _actions$: Actions,
        private _onlineOrdersService: Services.OnlineOrdersService,
        private _modalsServicService: Services.ModalsService,
        private _store: Store<StateModels.IStateShared>,
        private _cryptoService: Services.CryptoService
    ) { }
}
