import { Injectable, Inject } from '@angular/core';
import { Store } from '@ngrx/store';

import * as actions from '@shared/state/actions';
import * as State from '@shared/state/interface';
import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

import { CryptoService } from './crypto.shared.service';
import { LocationsService } from './locations.shared.service';
import { AppSettingsService } from './app-settings.shared.service';
import { VenueImagesService } from './venue-images.shared.service';
import { PaymentsService } from './payments.shared.service';
import { GoogleApiService } from './google';
@Injectable({
    providedIn: 'root',
})
export class InitService {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public store: Store<State.IStateShared>,
        public cryptoService: CryptoService,
        public locationsService: LocationsService,
        public appSettingsService: AppSettingsService,
        public venueImagesService: VenueImagesService,
        public googleApiService: GoogleApiService,
        public paymentsService: PaymentsService
    ) {
    }

    public async unsetSplashLoader(): Promise<boolean> {
        return true;
    }

    public async init(cleanSessionRedirect: boolean = false): Promise<boolean> {
        return new Promise(resolve => {
            if(this.config.payments.googlePay.enabled) {
                this.paymentsService.googlePayPaymentProviderService.addHtmlElementsToDOM();
            }
            /**
             * Add Adyen Script & Link elements to DOM
             */
            if (this.config.payments.baseProvider === OLO.Enums.PAYMENT_PROVIDER.ADYEN) {
                this.paymentsService.adyenPaymentProviderService.addHtmlElementsToDOM();
            }

            /**
             * Add Stripe Script
             */
            if(this.config.payments.baseProvider === OLO.Enums.PAYMENT_PROVIDER.STRIPE) {
                this.paymentsService.stripePaymentProviderService.addHtmlElementsToDOM();
            }

            /**
             * Add Stripe Script
             */
            if(this.config.payments.baseProvider === OLO.Enums.PAYMENT_PROVIDER.FAT_ZEBRA) {
                this.paymentsService.fatZebra3DSPaymentProviderService.addHtmlElementsToDOM();
            }
            //
            //  Set Google API script
            //
            // try {
            //     this.googleApiService.appendMapsScript();
            // } catch (ex) {
            //     console.error('Unable to add googleApiService');
            // }

            //
            //  Set base title for whole app getting data from config - base title is set in config file
            //  This is slow solution. Base title should be set directly in index.html file.
            //
            if (this.config.title) {
                Utils.Meta.setTags({ title: this.config.title });
            }

            //
            //  Some payment methods require redirecting and restoring state
            //
            if (Utils.Redirect.isRedirecting() && !cleanSessionRedirect) {
                return resolve(false);
            }
            Utils.Redirect.unsetRedirectAsync();
            Utils.Storage.remove(OLO.Enums.SESSION_STORAGE.STATE, 'sessionStorage');

            /* Check for session data */
            this.store.dispatch(actions.MemberSessionRequest());

            //
            //  Set app mode and device type
            //
            this.appSettingsService.init();

            //
            //  Get venue image if neede
            //
            if (this.config.venue && this.config.venue.id) {
                this.venueImagesService.requestVenueImage();
            }


            /* Restore current pickup time */
            try {
                const pickupTime: string = Utils.Storage.getItem(OLO.Enums.USER_STORAGE.CURRENT_PICKUP_TIME);

                if (pickupTime) {
                    let json: OLO.Ordering.IPickupTime = JSON.parse((pickupTime as unknown) as string);
                    json.PlaceOrderTimeout = new Date(json.PlaceOrderTimeout);
                    json.Date = new Date(json.Date);

                    if (json.Date < new Date()) {
                        Utils.Storage.remove(OLO.Enums.USER_STORAGE.CURRENT_PICKUP_TIME);
                    } else {
                        this.store.dispatch(actions.CurrentLocationPickupTimeRestore(json));
                    }
                }

            } catch (ex) {
                console.error('Unable to restore current pickup time');
            }

            /* Load saved cart contents */
            try {
                const cartData: string = Utils.Storage.getItem(OLO.Enums.CART_STORAGE.DATA);

                if (cartData) {
                    let json: State.ICart = JSON.parse(this.cryptoService.decrypt(cartData as string));
                    if (!json) throw new Error('Bad cart data');
                    this.store.dispatch(actions.CartLoad({ cart: json }));
                }

            } catch (ex) {
                Utils.Storage.remove(OLO.Enums.CART_STORAGE.DATA as unknown as string);
                console.error('Unable to restore cart\'s contents');
            }

            /* Set previously saved locationNo */
            const currentLocationNo: string = Utils.Storage.getItem(OLO.Enums.USER_STORAGE.CURRENT_LOCATION as unknown as string);
            if (currentLocationNo && !Number.isNaN(+currentLocationNo)) {
                this.store.dispatch(actions.CurrentLocationSet({ locationNo: +currentLocationNo }));
            }

            /* Request locations */
            this.locationsService.requestLocations();

            /* Init saved history orders */
            const historyOrdersData: string = Utils.Storage.getItem(OLO.Enums.HISTORY_ORDERS_STORAGE.DATA);

            if (!historyOrdersData) {
                Utils.Storage.set(OLO.Enums.HISTORY_ORDERS_STORAGE.DATA, this.cryptoService.encrypt(JSON.stringify([])));
            }

            /* Confirm application initializations */
            setTimeout(() => {
                this.appSettingsService.setInitAppInitFlag();
                resolve(true);
            }, 0);
        });

    }

}
