import { ICreatePaymentAccountRequest, ICreatePaymentAccountResponse, IPaginatedListPaymentAccountsListResponse, ICreditCardTokenResponse } from '@shared/state';
import * as Utils from '@shared/core/utils';

export class CreditCardsMapper {
    public static mapAddMemberCardRequest(card: ICreatePaymentAccountRequest): APIv3.MembersCreateMemberCard.Parameters.Request {
        return card ? {
            Token: card.Token,
            NiceName: card.NiceName,
            ExpirationDate: Utils.CreditCards.dateToApiFormat(card.ExpirationDate),
            CardType: card.CardType,
            PaymentProvider: card.PaymentProvider,
            IsDefault: card.IsDefault,
            BillingAddress: card?.BillingDetails ? {
                City: card.BillingDetails.City,
                CountryIso2Code: card.BillingDetails.CountryIso2Code,
                HouseNumber: card.BillingDetails.HouseNumber,
                PostalCode: card.BillingDetails.PostalCode,
                StateOrProvince: card.BillingDetails.StateOrProvince,
                Street: card.BillingDetails.Street,
            } : null,
            FatZebraToken: card.FatZebraToken ? {
                r: card.FatZebraToken.r,
                v: card.FatZebraToken.v,
            } : null,
            LocationNo: card.LocationNo,
        } : null;
    }

    public static mapAddMemberCardResponse(memberCard: APIv3.MembersCreateMemberCard.Responses.$200): ICreatePaymentAccountResponse {
        return memberCard ? {
            Id: memberCard.Id,
            DisplayName: memberCard.DisplayName,
            CardType: memberCard.CardType,
            ExpirationDate: memberCard.ExpirationDate,
            IsDefault: memberCard.IsDefault,
        } : null;
    }

    public static mapGetCardItems(cardItems: APIv3.MembersGetMemberCards.Responses.$200): IPaginatedListPaymentAccountsListResponse {
        return cardItems ? {
            PageNo: cardItems.PageNo,
            PageSize: cardItems.PageSize,
            Items: cardItems.Items ? cardItems.Items.map((item: APIv3.PaymentAccountsListResponse) => ({
                Id: item.Id,
                ExpirationDate: item.ExpirationDate,
                DisplayName: item.DisplayName,
                CardType: item.CardType,
                IsDefault: item.IsDefault,
                BillingDetails: item.BillingAddress ? {
                    CountryIso2Code: item.BillingAddress.CountryIso2Code ?? null,
                    StateOrProvince: item.BillingAddress.StateOrProvince ?? null,
                    City: item.BillingAddress.City ?? null,
                    PostalCode: item.BillingAddress.PostalCode ?? null,
                    Street: item.BillingAddress.Street ?? null,
                    HouseNumber: item.BillingAddress.HouseNumber ?? null,
                } : null,
            })) : null,
        } : null;
    }

    public static mapRemoveMemberCard(removeMemberCard: APIv3.MembersRemoveMemberCard.Responses.$200): boolean {
        return removeMemberCard ? removeMemberCard.IsSuccess : null;
    }

    public static mapMemberCreditCardToToloCreditCardDetail(card: Nullable<OLO.Members.IMemberCreditCardDetails>): Nullable<OLO.CreditCards.ICreditCardDetails> {
        return card
            ? {
                adyenPaymentData: card.AdyenPaymentData
                    ? {
                        encryptedCardNumber: card.AdyenPaymentData.encryptedCardNumber,
                        encryptedExpiryMonth: card.AdyenPaymentData.encryptedExpiryMonth,
                        encryptedExpiryYear: card.AdyenPaymentData.encryptedExpiryYear,
                        encryptedSecurityCode: card.AdyenPaymentData.encryptedSecurityCode,
                    }
                    : null,
                billingDetails: card.BillingDetails
                    ? {
                        City: card.BillingDetails.City,
                        PostalCode: card.BillingDetails.PostalCode,
                        Street: card.BillingDetails.Street,
                        StateOrProvince: card.BillingDetails.StateOrProvince,
                        HouseNumber: card.BillingDetails.HouseNumber,
                        CountryIso2Code: card.BillingDetails.CountryIso2Code,
                    }
                    : null,
                cardHolderName: card.CardHolderName,
                cardNumber: card.CardNumber ?? null,
                cvv: card.Cvc2 ?? null,
                expiryDate: card.ExpirationDate,
                isDefaultPaymentMethod: card.IsDefault ?? false,
                saveCard: card.SaveAwait ?? false,
                stripePaymentData: card.StripePaymentData
                    ? {
                        billing_details: card.StripePaymentData.billing_details,
                        card: card.StripePaymentData.card,
                        card_present: card.StripePaymentData.card_present,
                        created: card.StripePaymentData.created,
                        customer: card.StripePaymentData.customer,
                        fpx: card.StripePaymentData.fpx,
                        id: card.StripePaymentData.id,
                        ideal: card.StripePaymentData.ideal,
                        livemode: card.StripePaymentData.livemode,
                        metadata: card.StripePaymentData.metadata,
                        object: card.StripePaymentData.object,
                        sepa_debit: card.StripePaymentData.sepa_debit,
                        type: card.StripePaymentData.type,
                    }
                    : null,
            }
            : null;
    }
}
