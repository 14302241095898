import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IStateShared, IMembers } from '../interface';
import * as Utils from '@shared/core/utils';

const state = createFeatureSelector<IStateShared, IMembers>('members');

export const getMemberResetForgottenPasswordState = createSelector(
    state,
    members => members.forgottenPasswordReset
);

export const getMemberEmailConfirmState = createSelector(
    state,
    members => members.emailConfirm
);

export const getCurrentMember = createSelector(
    state,
    members => members.data
);

export const getGuestData = createSelector(
    state,
    members => members.guestData
);

export const getMemberState = createSelector(
    state,
    members => members
);

export const getAuthorizationStep = createSelector(
    state,
    members => members.authorizationStep
);

export const isGuestModeEnabled = createSelector(
    state,
    members => members.isGuestModeEnabled
);

export const isMemberPasswordInvalid = createSelector(
    state,
    members => members.signIn.hasFailed
);

export const isMemberPhoneVeryficationCodeInvalid = createSelector(
    state,
    members => members.verifyPhone.hasFailed
);

export const isNewMemberPhoneInvalid = createSelector(
    state,
    members => members.validatePhone.hasFailed
);

export const isNewMemberEmailInvalid = createSelector(
    state,
    members => members.validateEmail.hasFailed
);

export const isEmailValidated = createSelector(
    state,
    members => members.data ? members.data.IsEmailValidated : false
);

export const isMemberAuthorized = createSelector(
    state,
    members => members.isAuthorized
);

export const isMemberAuthorizedJWT = createSelector(
    state,
    members => members?.isAuthorized && members?.jwt.hasSucceeded || false
);

export const isMemberLoading = createSelector(
    state,
    members => (
        members.uniqueCode.isDownloading ||
        members.signUp.isSigningUp ||
        members.jwt.isDownloading ||
        members.changePassword.isChanging ||
        members.forgotPassword.isSending ||
        members.signIn.isSigningIn ||
        members.tokenForEmail.isSending ||
        members.tokenForPhone.isSending ||
        members.validateLogin.isValidating ||
        members.verifyPhone.isVerifying ||
        members.validateEmail.isValidating ||
        members.validatePhone.isValidating ||
        members.isDownloading ||
        members.setPassword.isSetting ||
        members.signUpPartialMember.isSigningUp ||
        members.update.isUpdating ||
        members.deleteProfile.isDeleting ||
        members.verifyLinkRewardsAccount.isVerifying ||
        members.linkRewardsAccount.isLinking
    )
);

export const isMemberUpdating = createSelector(
    state,
    members => (
        members.update.isUpdating ||
        members.verifyPhone.isVerifying ||
        members.validateEmail.isValidating
    )
);

export const isMemberUpdatingPassword = createSelector(
    state,
    members => members.changePassword.isChanging
);

export const getMemberProfile = createSelector(
    state,
    members => {

        if (members && members.data) {
            let phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
            let phone = members.data.MobilePhone;

            if (phoneRegex.test(phone)) {
                phone = phone.replace(phoneRegex, '($1)-$2-$3');
            }

            return {
                initials: `${members.data.MemberName[0]} ${members.data.MemberSurname[0]}`,
                memberName: `${members.data.MemberName} ${members.data.MemberSurname}`,
                memberPhone: phone,
            };
        }

        return {
            initials: null,
            memberName: null,
            memberPhone: null,
        };

    }
);

export const getMemberFullName = createSelector(
    state,
    members => {
        if (!members || !members.data) return null;

        return `${members.data.MemberName}${members.data.MemberSurname ? ' ' + members.data.MemberSurname : ''}`;
    }
);

export const memberProfileUpdateInProgress = createSelector(
    state,
    members => members.update.isUpdating
);
export const memberProfileUpdateSuccess = createSelector(
    state,
    members => members.update.updateHasSucceeded
);

export const memberProfileUpdateError = createSelector(
    state,
    members => members.update.updateHasFailed
);

export const memberPasswordUpdateState = createSelector(
    state,
    members => members.changePassword
);

export const memberSendPhoneVerificationError = createSelector(
    state,
    members => members.tokenForPhone.hasFailed
);

export const getObfuscatedMemberEmail = createSelector(
    state,
    members => {
        if (!members.data || !members.data.Email) return null;

        return Utils.Strings.obfuscateEmail(members.data.Email);
    }
);

export const getObfuscatedForgotMemberEmail = createSelector(
    state,
    members => {
        if (!members.forgotPassword || !members.forgotPassword.email) return null;

        return Utils.Strings.obfuscateEmail(members.forgotPassword.email);
    }
);

export const getMemberPhoneNo = createSelector(
    state,
    members => {
        let mobilePhone: string = null;
        switch (true) {
            case members.authorizationStep === OLO.Enums.AUTH_STEP.BEFORE_UPDATE_VERIFY_PHONE && members.update.data !== null && members.update.data.MobilePhone !== null:
                mobilePhone = members?.update?.data?.MobilePhone;
                break;
            case members.addData !== null && members.addData.MobilePhone !== null:
                mobilePhone = members?.addData?.MobilePhone;
                break;

            case members.data !== null && members.data.MobilePhone !== null:
                mobilePhone = members?.data?.MobilePhone;
                break;

            default:
                return null;
        }

        return mobilePhone;
    }
);


export const getObfuscatedMemberPhoneNo = createSelector(
    state,
    members => {
        let mobilePhone: string = null;
        switch (true) {
            case members.authorizationStep === OLO.Enums.AUTH_STEP.BEFORE_UPDATE_VERIFY_PHONE && members.update?.data?.MobilePhone != null:
                mobilePhone = members.update.data.MobilePhone;
                break;

            case members.addData?.MobilePhone != null:
                mobilePhone = members.addData.MobilePhone;
                break;

            case members.data?.MobilePhone != null:
                mobilePhone = members.data.MobilePhone;
                break;

            default:
                return null;
        }


        return Utils.Strings.obfuscateMobilePhone(mobilePhone);
    }
);

export const hasGuestSignedUp = createSelector(
    state,
    members => members.signUp.hasSucceeded === true
        && members.isGuestModeEnabled === true
        && members.data !== null
        && members.accountId !== null && members.accountId === members.data.MemberId
);

export const canCompleteAccountSetup = createSelector(
    state,
    members => members.data !== null && members.data.IsOnlineRegistered === false && members.isGuestModeEnabled === true && members.isAuthorized === false
);

export const passwordChangeError = createSelector(
    state,
    members => {
        if (!members.changePassword.hasFailed) return null;
        if (members.changePassword.error && members.changePassword.error.Message) return members.changePassword.error;

        return {
            changePasswordError: 'Password change error',
        };
    }
);

export const isCheckingTokensForEmailConfirmation = createSelector(
    state,
    members => members.emailConfirm.isConfirming || members.emailConfirm.token.isValidating
);

export const isEmailGUIDTokenValid = createSelector(
    state,
    members => {
        if (members.emailConfirm.token.isValidating) return null;

        return members.emailConfirm.token.hasSucceeded || members.emailConfirm.token.hasFailed;
    }
);

export const isEmailConfirmationSuccess = createSelector(
    state,
    members => {
        if (members.emailConfirm.isConfirming) return null;

        return members.emailConfirm.hasSucceeded || members.emailConfirm.hasFailed;
    }
);

export const canOmmitEmailVerification = (configCanOmmit: boolean) => createSelector(
    state,
    members => {
        if (members.isDownloading === true) return null;

        return configCanOmmit === true && (members.addData !== null || members.data !== null);
    }
);

export const partialMemberSignUpFailed = createSelector(
    state,
    members => members.signUpPartialMember.hasFailed
);

export const isDownloadingLoyaltyProducts = createSelector(
    state,
    members => members.loyaltyProducts.isDownloading
);

export const loyaltyProducts = createSelector(
    state,
    members => members.loyaltyProducts
);

export const isDownloadingFreeProducts = createSelector(
    state,
    members => members.freeProducts.isDownloading,
);

export const freeValidProducts = createSelector(
    state,
    members => {
        if (!members.data || !members.freeProducts.data) return null;

        const now: Date = new Date();

        return members.freeProducts.data.filter(obj => obj.MemberId === members.data.MemberId && new Date(obj.ExpiryDate) > now && obj.IsRedeemed === false);
    }
);

export const getFreeProduct = (freeProductId: number) => createSelector(
    state,
    members => {
        if (!members.data || !members.freeProducts.data) return null;

        return members.freeProducts.data.find(obj => obj.FreeProductId === freeProductId) || null;
    }
);

export const showAccountPaymentMethod = (config: IConfig) => createSelector(
    state,
    members => {
        if (config.accountCharge?.enabled !== true) return false;

        return members.data?.HasAccount === true;
    }
);

export const getAccountBalance = createSelector(
    state,
    members => members.accountBalance
);

export const isDownloadingAccountBalance = createSelector(
    state,
    members => members.accountBalance.isDownloading === true,
);

export const accountBalanceIsValid = createSelector(
    state,
    members => members.accountBalance.data?.AvailableBalance > 0 || false
);

export const showAccountBalance = (config: IConfig) => createSelector(
    state,
    members => {
        if (config.appMode === IAppMode.LOYALTY_ONLY || members.data?.HasAccount !== true || config.accountCharge?.enabled !== true) return false;

        return members.data?.HasAccount === true;
    }
);

export const isAccountSet = (config: IConfig) => createSelector(
    state,
    members => {
        if (config.accountCharge?.enabled !== true) return false;

        return members.data?.HasAccount === true;
    }
);

export const getGuestValidationDetails = createSelector(
    state,
    members => members.guestValidationDetails
);

export const getMobilePhoneCountryId = createSelector(
    state,
    members => members.update?.data?.MobilePhoneCountryId || members.data?.MobilePhoneCountryId
);

export const getMemberPoints = createSelector(
    state,
    members => members.data ? members.data.MemberPoints : null
);

export const isMemberDataValid = createSelector(
    state,
    members => members.data !== null || members.guestData !== null,
);

export const isGuestMember = createSelector(
    isGuestModeEnabled,
    isMemberAuthorizedJWT,
    (guestModeEnabled, isAuthorized) => guestModeEnabled && !isAuthorized
);
